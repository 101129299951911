import React, {useEffect, useState} from "react";
import styles from "./Films.module.scss";
import {useNavigate} from "react-router-dom";
import {useDispatch} from 'react-redux'
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import {sortArrayObjectsByField} from "../../utils/utility";
import {getCardCollection, getCrumbs} from "../../utils/cardFunction";
import {addDataCollection} from "../../store/dataCollection";
import {CONFIG_DATA_COLLECTION} from "../../utils/config";

function Films() {

   const navigate = useNavigate();
   const [dataCard, setDataCard] = useState(null)
   const [isReady, setIsReady] = useState(false)
   const dispatch = useDispatch();
   const [crumbs, setCrumbs] = useState(null)

   const handlerOnClickFilms = (pk) => {
      navigate(`/films/${pk}`, {replace: false})
   }

   useEffect(() => {
      setIsReady(false)
      getCardCollection('films')
         .then((res) => {
            setDataCard(sortArrayObjectsByField(res, 'pk')) // сортировка по полю name
            setCrumbs(getCrumbs('films')) // хлебные крошки
            setIsReady(true)
            dispatch(addDataCollection({
               collection: CONFIG_DATA_COLLECTION['films'].collection,
               data: res,
            }))
         })

      // Прокрутка к верху экрана
      window.scrollTo(0, 0)
   }, [])

   return (
      isReady &&
      <div className={styles.films}>
         <BreadCrumbs crumbs={crumbs}/>
         <ul className={styles.filmsList}>
            {dataCard.map((item) => (
               <li key={item.pk} className={styles.filmsList__item}>
                  <div className={styles.filmsList__imageContainer}>
                     <img src={item.image_url} alt="" className={styles.filmsList__image}/>
                     <img src={item.image_url_min} alt="" className={styles.filmsList__imageMin}/>
                     <div className={styles.filmsList__textOverlay} onClick={() => handlerOnClickFilms(item.pk)}></div>
                     <p className={styles.filmsList__textImage} onClick={() => handlerOnClickFilms(item.pk)}>{item.opening_crawl}</p>
                  </div>
               < /li>
            ))}
         </ul>

      </div>
   );
}

export default Films;
