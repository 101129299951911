import React from 'react';
import styles from './Sidebar.module.scss'
import {SidebarLink} from "./SidebarLink/SidebarLink";

function Sidebar() {

   return (
      <>
         <div className={styles.sidebar}>
            <ul className={styles.sidebar__navigate}>
               <li>
                  <SidebarLink to="/">Главная</SidebarLink>
               </li>
               <li>
                  <SidebarLink to="/films">Фильмы</SidebarLink>
               </li>
               <li>
                  <SidebarLink to="/planets">Планеты</SidebarLink>
               </li>
               <li>
                  <SidebarLink to="/people">Персонажи</SidebarLink>
               </li>
               <li>
                  <SidebarLink to="/species">Расы</SidebarLink>
               </li>
               <li>
                  <SidebarLink to="/starships">Звездолеты</SidebarLink>
               </li>
               <li>
                  <SidebarLink to="/vehicles">Транспорт</SidebarLink>
               </li>
            </ul>
         </div>
      </>
   );
}

export default Sidebar;