import styles from "./Overlay.module.scss";
import React, {useEffect} from "react";

export const Overlay = ({isOpened, children, onClose, isTransparent, isClosableOverlay}) => {

   const handlerClickOnOverlay = (e) => {
      if (isClosableOverlay) {
         document.body.style.overflow = "auto";
         document.body.style.paddingRight = "0";
         onClose()
      }
   }

   function handlerKeyPress(e) {
      if (isClosableOverlay) {
         if (e.code === "Escape") {
            e.preventDefault();
            onClose()
         }
      }
   }

   useEffect(() => {
      // Добавление слушателя нажатия клавиши при
      document.addEventListener("keydown", handlerKeyPress);

      // Удаление слушателя при размонтировании
      return () => {
         document.removeEventListener("keydown", handlerKeyPress);
      }
   }, [])

   return (
      <div className={isOpened ? `${styles.container}` : `${styles.nonactive}`}>
         <div className={isTransparent ? `${styles.overlay} ${styles.overlay_transparent}` : `${styles.overlay}`}
              onClick={handlerClickOnOverlay}/>
         {children}
      </div>

   );
};