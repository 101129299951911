import fairebaseApi from "./FairebaseApi";
import {CONFIG_DATA_COLLECTION} from "./config";

//--------------------------------------------------------------------------------------
// Получение коллекции из базы данных по имени коллекции
//--------------------------------------------------------------------------------------
export async function getCardCollection(collection) {
   return await fairebaseApi.getCollection(CONFIG_DATA_COLLECTION[collection].collection);
}

//--------------------------------------------------------------------------------------
// Получение документа из коллекции по указанному полю
//--------------------------------------------------------------------------------------
export const getDocumentFromCollection = (collection, fieldName, value) => {
   return fairebaseApi.getDocument(CONFIG_DATA_COLLECTION[collection].collection, fieldName, value)
      .then((res) => {
         return {
            cardData: res.currentDocumentData[0],
            cardId: res.currentDocumentId,
         }
      })
      .catch((error) => {
         console.log('Ошибка получения документа из коллекции', error)
      })
}

// --------------------------------------------------------------------------------------
// Получение описания карточки
// --------------------------------------------------------------------------------------
export const getCardDescription = (collection, fieldName, value) => {
   return fairebaseApi.getDocument(CONFIG_DATA_COLLECTION[collection].description, fieldName, value)
      .then((res) => {
         return {
            cardData: res.currentDocumentData[0],
            cardId: res.currentDocumentId,
         }
      })
      .catch((error) => {
         console.log("Ошибка загрузки описания", error.code)
      })
}

//--------------------------------------------------------------------------------------
// Получение карточки
//--------------------------------------------------------------------------------------
export async function getCard(collection, pk) {
   return await fairebaseApi.getDocument(CONFIG_DATA_COLLECTION[collection].collection, 'pk', pk);
}


//--------------------------------------------------------------------------------------
// Получение хлебных крошек для отображения в <CardList>
//--------------------------------------------------------------------------------------
export const getCrumbs = (collection) => {
   return [
      {
         menu: 'Главная',
         path: '',
      },
      {
         menu: ` ${CONFIG_DATA_COLLECTION[collection].title}`,
         path: collection,
      },
   ]
}

//--------------------------------------------------------------------------------------
// Получение хлебных крошек для отображения в <CardInfo>
//--------------------------------------------------------------------------------------
export const getCrumbsCardInfo = (collection, cardName) => {
   return [
      {
         menu: 'Главная',
         path: '',
      },
      {
         menu: ` ${CONFIG_DATA_COLLECTION[collection].title}`,
         path: collection,
      },
      {
         menu: cardName,
      },
   ]
}