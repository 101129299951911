import React from 'react';
import styles from './FieldText.module.scss'

function FieldText({nameField, text, posfix}) {

   return (
      <div className={styles.field}>
         <p className={styles.field__name}>{nameField} : </p>
         <p className={styles.field__text}>{text} {posfix ? posfix : ''}</p>
      </div>
   );
}

export default FieldText;
