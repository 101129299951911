import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import styles from './ResetPassword.module.scss'
import Front1 from '../../image/front_1.png'
import Front2 from '../../image/front_2.png'
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import {isDesktop} from "react-device-detect";

function ResetPassword() {

   const navigate = useNavigate();
   const location = useLocation();
   const {signIn} = useAuth();
   const fromPage = location.state?.from?.pathname || '/';

   const [currentError, setCurrentError] = useState("");
   const [loginState, setLoginState] = useState(
      {
         email: '',
      }
   )
   const [isValid, setIsValid] = useState(false);
   const [errorMessageEmail, setErrorMessageEmail] = useState("")

   // Обработчик изменений в поле ввода
   const handleChange = (e) => {
      const {name, value} = e.target;
      setLoginState(prevState => ({...prevState, [name]: value}));
   }

   // Обработчик кнопки submit формы
   const handleSubmit = (e) => {
      e.preventDefault();
      const auth = getAuth();
      auth.languageCode = 'ru';
      sendPasswordResetEmail(auth, loginState.email)
          .then(() => {
             navigate('/sentreset', {replace: true})
          })
         .catch((error) => {
            if (error.message === 'Firebase: Error (auth/user-not-found).') {
               setCurrentError('Пользователь с таким e-mail не зарегистрирован.')
            }
         });
   }

   // При каждом изменении в поле ввода email или password - проверка на корректность ввода
   useEffect(() => {
      const emailValidity = loginState.email.match(/^[\w-\.\d*\.]+@[\w\d]+(\.\w{2,4})$/);
      emailValidity ? setErrorMessageEmail("") : setErrorMessageEmail("Поле должно содержать e-mail")
      setIsValid(emailValidity);
   }, [loginState.email])

   // При установке фокуса на поле ввода снятие readOnly
   // Первоначально readOnly={true}. Реализовано для того, чтобы не было автозаполнения полей ввода
   const handlerOnFocus = (e) => {
      if (isDesktop) {
         e.target.readOnly = false
      }
   }

   return (
      <div className={styles.login}>
         <div className={styles.login__block}>
            <form action="#"
                  onSubmit={handleSubmit}
                  className={styles.login__form}
                  name='register' noValidate
                  autoComplete="off">
               <h2 className={styles.login__title}>Забыли пароль?</h2>
               <p className={styles.login__name}>E-mail</p>
               <input type="text"
                      readOnly={isDesktop}
                      onFocus={handlerOnFocus}
                      value={loginState.email}
                      onChange={handleChange}
                      className={styles.login__input}
                      name="email"
                      placeholder="введите е-mail"
                      minLength="2"
                      maxLength="40" required/>
               <p className={styles.login__inputError}>{errorMessageEmail}</p>
               <p className={styles.login__error}>{currentError}</p>
               <button type="submit"
                       aria-label="submit"
                       className={isValid ? `${styles.login__submit}`
                          : `${styles.login__submit} ${styles.login__submit_disabled}`}
                       disabled={!isValid}
                       name="form_submit">
                  Сбросить пароль
               </button>
               <p className={styles.login__name}>Вспомнили пароль?&nbsp;
                  <Link className={styles.login__link} to='/login'>Войти</Link>
               </p>
            </form>
         </div>

         <div className={styles.login__image}>
            <img src={Front1} alt="" className={styles.login__topImage}/>
            <img src={Front2} alt="" className={styles.login__bottomImage}/>
         </div>

      </div>

   );
}

export default ResetPassword;
