import React from 'react';
import styles from './Card.module.scss'
import DefaultAvatar from '../../image/avatar-default.png'
import {useNavigate} from "react-router-dom";
import {CARD_CONFIG} from "../../utils/config";

function Card({currentCardData, collectionName}) {

   const navigate = useNavigate()

   const handlerOnClickButtonNext = () => {
      navigate(`/${collectionName}/${currentCardData.pk}`, {replace: false})
   }

   return (
      currentCardData &&
      <div className={styles.card}>
         <div className={styles.content}>
            <img src={currentCardData.avatar_url
               ? currentCardData.avatar_url
               : DefaultAvatar} alt=""
                 className={styles.content__image}/>
            <ul className={styles.content__infoContent}>
               {CARD_CONFIG[collectionName].content.map((item, index) => (
                  <li className={styles.content__infoText} key={index}>
                     <p className={styles.content__title}>{item.name}:</p>
                     {
                        !item.link
                           ? <p className={styles.content__text}>{currentCardData[item.field_name] || 'нет данных'}</p>
                           : <p className={styles.content__text}>
                              {currentCardData[item.field_name] && currentCardData[item.field_name][0]
                           ? currentCardData[item.field_name][0].name
                           : 'нет данных'}</p>
                     }
                  </li>
               ))}
            </ul>
         </div>

         <div className={styles.content__brief}>
            <p className={styles.content__textBrief}>
               {currentCardData.brief ? currentCardData.brief : 'нет данных'}
            </p>

            <button type="text"
                    aria-label="submit"
                    className={styles.card__button}
                    name="next"
                    onClick={handlerOnClickButtonNext}>
               Подробнее...
            </button>

         </div>

      </div>
   );
}

export default Card;