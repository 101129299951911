import Portal from "../../Portal/Portal";
import {Overlay} from "../Overlay/Overlay";
import React from "react";

export const Popup = ({isOpened, onClose, children, isTransparent, isClosableOverlay, onClickOK}) => {

   return (
      <Portal>
         <Overlay onClose={onClose}
                  isOpened={isOpened}
                  isTransparent={isTransparent}
                  isClosableOverlay={isClosableOverlay}
                  onClickOK={isClosableOverlay}>
            {children}
         </Overlay>
      </Portal>

   );
};