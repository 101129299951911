import React, {useEffect} from "react";
import styles from './Home.module.scss'

function Home() {

   useEffect(() => {
      // Прокрутка к верху
      window.scrollTo(0, 0)
   }, [])

   return (

      <div className={styles.home}>
         <h1 className={styles.home__titleHome}>Главная страница</h1>

      </div>
   );
}

export default Home;