import {Navigate, useLocation} from 'react-router-dom';
import {getUserFromSessionStorage} from "../../utils/utility";

const ProtectedMainRoute = ({children, isVerify}) => {
    const location = useLocation();

    if (!isVerify) {
        return <Navigate to='/email-not-verified' state={{from: location}}/>
    }

    return children;
}

export {ProtectedMainRoute};