import React, {useEffect, useState} from 'react';
import styles from './CardList.module.scss'
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {getUserFromSessionStorage, sortArrayObjectsByField} from "../../utils/utility";
import {getCardCollection, getCrumbs} from "../../utils/cardFunction";
import Card from "../Card/Card";
import {useDispatch} from "react-redux";
import {addDataCollection} from "../../store/dataCollection";
import {CONFIG_DATA_COLLECTION} from "../../utils/config";
import ADDCARD from '../../image/add-new-card.png'

function CardList() {

   const dispatch = useDispatch();
   const {state} = useLocation()
   const {collection} = useParams()
   const navigate = useNavigate()
   const [dataCard, setDataCard] = useState(null)
   const [crumbs, setCrumbs] = useState(null)
   const [isReady, setIsReady] = useState(false)

   const handlerOnClickButtonAdd = (path) => {
      navigate(`/${collection}/add-card`, {replace: true,});
   }

   useEffect(() => {
      // if (dataCard && state && state.newCard) {
      //    setDataCard(prevState => [state.newCard, ...prevState])
      // } else {
         getCardCollection(collection) // Получение коллекции карточек
            .then((res) => {
               setDataCard(sortArrayObjectsByField(res, 'name')) // сортировка по полю name
               if (state && state.newCard) {
                  setDataCard(prevState => [state.newCard, ...prevState])
               }
               setCrumbs(getCrumbs(collection)) // хлебные крошки
               setIsReady(true)
               dispatch(addDataCollection({
                  collection: CONFIG_DATA_COLLECTION[collection].collection,
                  data: res,
               }))
            })
      // }

      // Прокрутка к верху
      window.scrollTo(0, 0)
   }, [collection, state])

   return (
      isReady && dataCard &&
      <div className={styles.cardList}>
         <div className={styles.cardList__cramb}> {}
            <BreadCrumbs crumbs={crumbs}/>
            {/*<button type='button'*/}
            {/*        className={styles.cardList__buttonAdd}*/}
            {/*        onClick={handlerOnClickButtonAdd}*/}
            {/*>*/}
            {/*   Add*/}
            {/*</button>*/}

         </div>

         <ul className={styles.cardList__list}>
            <div className={getUserFromSessionStorage().rights && getUserFromSessionStorage().rights.add
               ? `${styles.cardList__addCard}`
               : `${styles.cardList__noVisible}`}
                 onClick={handlerOnClickButtonAdd}>
               <img className={styles.cardList__imgNewCard} src={ADDCARD} alt=""/>
            </div>
            {dataCard.map((item, index) => (
               <Card
                  currentCardData={item}
                  collectionName={collection}
                  key={index}
               />
            ))}
         </ul>

      </div>
   );
}

export default CardList;