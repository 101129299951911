import React, {useEffect, useState} from "react";
import styles from "./UserPage.module.scss";
import UserPageAvatar from "./UserPageAvatar/UserPageAvatar";
import {checkCurrentUserDocument} from "../../utils/utility";
import Input from "../../components/FormField/Input/Input";
import CheckBox from "../../components/CheckBox/CheckBox";
import TextArea from "../../components/FormField/TextArea/TextArea";
import FairebaseApi from "../../utils/FairebaseApi";
import UserProfileNotExist from "../UserProfileNotExist/UserProfileNotExist";
import {updateUserNameToFirebase} from "../../utils/firebaseUtility";

function UserPage({onChangeUserName}) {

   const [currentUserData, setCurrentUserData] = useState(null)
   const [userProfileData, setUserProfileData] = useState(null)

   const [currentUserAvatarUrl, setCurrentUserAvatarUrl] = useState('')
   const [isReady, setIsReady] = useState(false)
   const [userID, setUserID] = useState(null)
   const [pageState, setPageState] = useState(null)
   const [isButtonSubmitEnabled, setIsButtonSubmitEnabled] = useState(false)
   const [isButtonSCancelEnabled, setIsButtonCancelEnabled] = useState(false)

   const handlerChangeAvatarUrl = (url) => {
      setCurrentUserAvatarUrl(url)
   }

   const handlerOnSubmit = (evt) => {
      evt.preventDefault();
      setIsButtonSubmitEnabled(false)
      setIsButtonCancelEnabled(false)
      console.log(pageState, userID)
      FairebaseApi.updateDocToCollection('dataUsers', pageState, userID)
         .then((res) => {
            console.log('Данные успешно обновлены')
            setPageState(null)
         })
         .catch((err) => {
            console.log('Ошибка обновления данных', err)
         })

      // Если произошли изменения в поле Имя пользователя
      if (pageState['user_data.user_name.data']) {
         updateUserNameToFirebase(pageState['user_data.user_name.data'])
            .then((res) => {
               sessionStorage.setItem('currentUser', JSON.stringify(res));
               onChangeUserName(pageState['user_data.user_name.data'])
            })
      }

   }

   const handlerOnCancel = (evt) => {
      evt.preventDefault();
      document.getElementById('settings-form').reset()
      setPageState(null)
      setIsButtonSubmitEnabled(false)
      setIsButtonCancelEnabled(false)
   }

   const handlerOnChange = (name, value) => {
      setPageState(prevState => ({...prevState, [name]: value}));
      setIsButtonSubmitEnabled(true)
      setIsButtonCancelEnabled(true)
   }

   const getUserData = () => {
      const userAuthFromSessionStorage = JSON.parse(sessionStorage.getItem('currentUser'))
      checkCurrentUserDocument(userAuthFromSessionStorage.email, userAuthFromSessionStorage.uid)
         .then((res) => {
            const {isProfileExist, profileData} = res;
            if (isProfileExist) {
               setCurrentUserAvatarUrl(profileData.currentDocumentData[0].avatar_url)
               setUserID(profileData.currentDocumentId)
               setCurrentUserData(profileData.currentDocumentData[0].user_data)
               setIsReady(true)
            } else {
               setUserProfileData(profileData)
               setIsReady(true)
            }
         })
         .catch((err) => {
            console.log('Ошибка получения данных', err)
         })
   }

   useEffect(() => {
      getUserData()
   }, [])

   if (isReady && !currentUserData) {
      return (
         <UserProfileNotExist user_data={userProfileData}
                              onCreateProfile={() => getUserData()}
         />
      )
   }

   return (
      isReady && currentUserData &&
      <div className={styles.personInfo}>
         <h1 className={styles.personInfo__titleInfo}>Страница пользователя</h1>

         <div className={styles.personInfo__mainInfo}>

            <div>
               <UserPageAvatar avatarUrl={currentUserAvatarUrl}
                               onChangeAvatar={handlerChangeAvatarUrl}
                               userEmail={currentUserData.email}/>
            </div>

            <div className={styles.personInfo__content}>

               <form action="#"
                     id='settings-form'
                     onSubmit={handlerOnSubmit}
                     className={styles.personInfo__list}
                     name='userPage'
                     noValidate
               >
                  <div className={styles.personInfo__items}>
                     <p className={styles.personInfo__itemsName}>
                        {currentUserData.user_name.name[0].toUpperCase() + currentUserData.user_name.name.slice(1)}:&nbsp;</p>
                     <Input inputValue={currentUserData.user_name.data}
                            changeValue={handlerOnChange}
                            inputName={'user_data.user_name.data'}
                            buttonEdit={true}
                     />

                  </div>

                  <div className={styles.personInfo_itemsLocation}>
                     <div className={styles.personInfo_itemsLocation__locationInputArea}>
                        <p className={`${styles.personInfo__itemsName} ${styles.personInfo__itemsName_location}`}>
                           {currentUserData.location.name[0].toUpperCase() + currentUserData.location.name.slice(1)}:&nbsp;</p>
                        <TextArea inputValue={currentUserData.location.data}
                                  changeValue={handlerOnChange}
                                  inputName={'user_data.location.data'}
                                  buttonEdit={true}
                        />
                     </div>
                     <div className={styles.personInfo_itemsLocation__locationCheckArea}>
                        <CheckBox inputName={'user_data.location.public'}
                                  changeValue={handlerOnChange}
                                  isChecked={currentUserData.location.public}
                        />
                        <p className={styles.personInfo__itemsName}>Публичная:&nbsp;&nbsp;</p>
                     </div>
                  </div>

                  <div className={styles.personInfo__items}>
                     <p className={styles.personInfo__itemsName}>
                        {currentUserData.species.name[0].toUpperCase() + currentUserData.species.name.slice(1)}:&nbsp;</p>
                     <Input inputValue={currentUserData.species.data}
                            changeValue={handlerOnChange}
                            inputName={'user_data.species.data'}
                            buttonEdit={true}
                     />
                     <div className={styles.personInfo__checkArea}>
                        <CheckBox inputName={'user_data.species.public'}
                                  changeValue={handlerOnChange}
                                  isChecked={currentUserData.species.public}
                        />
                        <p className={styles.personInfo__itemsName}>Публичная:&nbsp;&nbsp;</p>
                     </div>
                  </div>

                  <div className={styles.personInfo__items}>
                     <p className={styles.personInfo__itemsName}>
                        {currentUserData.gender.name[0].toUpperCase() + currentUserData.gender.name.slice(1)}:&nbsp;</p>
                     <Input inputValue={currentUserData.gender.data}
                            changeValue={handlerOnChange}
                            inputName={'user_data.gender.data'}
                            buttonEdit={true}
                     />
                     <div className={styles.personInfo__checkArea}>
                        <CheckBox inputName={'user_data.gender.public'}
                                  changeValue={handlerOnChange}
                                  isChecked={currentUserData.gender.public}
                        />
                        <p className={styles.personInfo__itemsName}>Публичная:&nbsp;&nbsp;</p>
                     </div>
                  </div>

                  <div className={styles.personInfo__items}>
                     <p className={styles.personInfo__itemsName}>
                        {currentUserData.age.name[0].toUpperCase() + currentUserData.age.name.slice(1)}:&nbsp;</p>
                     <Input inputValue={currentUserData.age.data}
                            changeValue={handlerOnChange}
                            inputName={'user_data.age.data'}
                            buttonEdit={true}
                     />
                     <div className={styles.personInfo__checkArea}>
                        <CheckBox inputName={'user_data.age.public'}
                                  changeValue={handlerOnChange}
                                  isChecked={currentUserData.age.public}
                        />
                        <p className={styles.personInfo__itemsName}>Публичная:&nbsp;&nbsp;</p>
                     </div>
                  </div>

                  <div className={styles.personInfo__items}>
                     <p className={styles.personInfo__itemsName}>
                        {currentUserData.height.name[0].toUpperCase() + currentUserData.height.name.slice(1)}:&nbsp;</p>
                     <Input inputValue={currentUserData.height.data}
                            changeValue={handlerOnChange}
                            inputName={'user_data.height.data'}
                            buttonEdit={true}
                     />
                     <div className={styles.personInfo__checkArea}>
                        <CheckBox inputName={'user_data.height.public'}
                                  changeValue={handlerOnChange}
                                  isChecked={currentUserData.height.public}
                        />
                        <p className={styles.personInfo__itemsName}>Публичная:&nbsp;&nbsp;</p>
                     </div>
                  </div>

                  <div className={styles.personInfo__items}>
                     <p className={styles.personInfo__itemsName}>
                        {currentUserData.weight.name[0].toUpperCase() + currentUserData.weight.name.slice(1)}:&nbsp;</p>
                     <Input inputValue={currentUserData.weight.data}
                            changeValue={handlerOnChange}
                            inputName={'user_data.weight.data'}
                            buttonEdit={true}
                     />
                     <div className={styles.personInfo__checkArea}>
                        <CheckBox inputName={'user_data.weight.public'}
                                  changeValue={handlerOnChange}
                                  isChecked={currentUserData.weight.public}
                        />
                        <p className={styles.personInfo__itemsName}>Публичная:&nbsp;&nbsp;</p>
                     </div>
                  </div>

                  <div className={styles.personInfo__items}>
                     <p className={styles.personInfo__itemsName}>
                        {currentUserData.eye_color.name[0].toUpperCase() + currentUserData.eye_color.name.slice(1)}:&nbsp;</p>
                     <Input inputValue={currentUserData.eye_color.data}
                            changeValue={handlerOnChange}
                            inputName={'user_data.eye_color.data'}
                            buttonEdit={true}
                     />
                     <div className={styles.personInfo__checkArea}>
                        <CheckBox inputName={'user_data.eye_color.public'}
                                  changeValue={handlerOnChange}
                                  isChecked={currentUserData.eye_color.public}
                        />
                        <p className={styles.personInfo__itemsName}>Публичная:&nbsp;&nbsp;</p>
                     </div>
                  </div>

                  <div className={styles.personInfo__items}>
                     <p className={styles.personInfo__itemsName}>
                        {currentUserData.hair_color.name[0].toUpperCase() + currentUserData.hair_color.name.slice(1)}:&nbsp;</p>
                     <Input inputValue={currentUserData.hair_color.data}
                            changeValue={handlerOnChange}
                            inputName={'user_data.hair_color.data'}
                            buttonEdit={true}
                     />
                     <div className={styles.personInfo__checkArea}>
                        <CheckBox inputName={'user_data.hair_color.public'}
                                  changeValue={handlerOnChange}
                                  isChecked={currentUserData.hair_color.public}
                        />
                        <p className={styles.personInfo__itemsName}>Публичная:&nbsp;&nbsp;</p>
                     </div>
                  </div>

                  <div className={styles.personInfo__items}>
                     <p className={styles.personInfo__itemsName}>
                        {currentUserData.skin_color.name[0].toUpperCase() + currentUserData.skin_color.name.slice(1)}:&nbsp;</p>
                     <Input inputValue={currentUserData.skin_color.data}
                            changeValue={handlerOnChange}
                            inputName={'user_data.skin_color.data'}
                            buttonEdit={true}
                     />
                     <div className={styles.personInfo__checkArea}>
                        <CheckBox inputName={'user_data.skin_color.public'}
                                  changeValue={handlerOnChange}
                                  isChecked={currentUserData.skin_color.public}
                        />
                        <p className={styles.personInfo__itemsName}>Публичная:&nbsp;&nbsp;</p>
                     </div>
                  </div>

                  <div className={styles.personInfo__buttonArea}>
                     <button type="submit"
                             aria-label="submit"
                             className={isButtonSubmitEnabled ? `${styles.personInfo__button} ${styles.personInfo__button_save}`
                                : `${styles.personInfo__button} ${styles.personInfo__button_save} ${styles.personInfo__button_disabled}`}
                             disabled={!isButtonSubmitEnabled}
                             name="form_submit">
                        Сохранить
                     </button>

                     <button type="button"
                             className={isButtonSCancelEnabled ? `${styles.personInfo__button} ${styles.personInfo__button_cancel}`
                                : `${styles.personInfo__button} ${styles.personInfo__button_cancel} ${styles.personInfo__button_disabled}`}
                             disabled={!isButtonSCancelEnabled}
                             onClick={handlerOnCancel}
                     >
                        Отменить
                     </button>
                  </div>
               </form>

            </div>

         </div>

      </div>

   );
}

export default UserPage;
