import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import {getAuth, updateProfile} from "firebase/auth";

// Загрузка файла в Firebase Storage
// Возвращает загруженного URL файла для последующего скачивания
// folder - каталог в который сохранять (можно указывать несуществующий, тогда он автоматически создается)
// file - файл в формате input.files, fileName - имя сохраняемого файла
export function uploadFileToFirebaseStorage(folder, file, fileName) {
   return new Promise(function (resolve, reject) {
   const storage = getStorage();
   // Create the file metadata
   const metadata = {
      contentType: 'image/jpeg'
   };
   const storageRef = ref(storage, folder + fileName);
   // const uploadTask = uploadBytesResumable(storageRef, file, metadata);
   const uploadTask = uploadBytesResumable(storageRef, file, metadata);

   // Listen for state changes, errors, and completion of the upload.
   uploadTask.on('state_changed',
      (snapshot) => {
         // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
         const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
         switch (snapshot.state) {
            case 'paused':
               // console.log('Upload is paused');
               break;
            case 'running':
               // console.log('Upload is running');
               break;
         }
      },
      (error) => {
         switch (error.code) {
            case 'storage/unauthorized':
               reject(new Error(`storage/unauthorized`));
               break;
            case 'storage/canceled':
               reject(new Error(`storage/canceled`));
               break;
            case 'storage/unknown':
               reject(new Error(`storage/unknown`));
               break;
         }
      },
      () => {
         getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
         });
      }
   );
   });
}

export function updateAvatarToFirebase (avatar) {
   return new Promise(function (resolve, reject) {
      const auth = getAuth();
      updateProfile(auth.currentUser, {
         photoURL: avatar
      }).then(() => {
         const user = {
            email: auth.currentUser.email,
            id: auth.currentUser.uid,
            token: auth.currentUser.accessToken,
            name: auth.currentUser.displayName || 'Пришелец',
            createdAt: auth.currentUser.metadata.createdAt,
            photoURL: auth.currentUser.photoURL,
         }
         resolve(user);
         return user
      }).catch((error) => {
         reject(new Error(error));
      });
   });
}


export function updateUserNameToFirebase (name) {
   return new Promise(function (resolve, reject) {
      const auth = getAuth();
      updateProfile(auth.currentUser, {
         displayName: name
      }).then(() => {
         const user = {
            email: auth.currentUser.email,
            id: auth.currentUser.uid,
            emailVerified: auth.currentUser.emailVerified,
            userName: auth.currentUser.displayName || 'Пришелец',
         }
         resolve(user);
         return user
      }).catch((error) => {
         reject(new Error(error));
      });
   });
}