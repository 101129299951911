import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import styles from './Login.module.scss'
import Front1 from '../../image/front_1.png'
import Front2 from '../../image/front_2.png'
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import {isDesktop, isMobile} from 'react-device-detect';

function Login({onLogIn}) {

   const location = useLocation();
   const fromPage = location.state?.from?.pathname || '/';
   const [currentError, setCurrentError] = useState("");
   const [loginState, setLoginState] = useState(
      {
         email: '',
         password: '',
      }
   )

   // Константы проверок на ошибки ввода
   const [isValid, setIsValid] = useState(false);
   const [errorMessageEmail, setErrorMessageEmail] = useState("")
   const [errorMessagePassword, setErrorMessagePassword] = useState("")

   // Обработчик изменений в поле ввода
   const handleChange = (e) => {
      const {name, value} = e.target;
      setLoginState(prevState => ({...prevState, [name]: value}));
      setCurrentError('')
   }

   // Обработчик кнопки submit формы
   const handleSubmit = (e) => {
      e.preventDefault();
      const auth = getAuth();
      signInWithEmailAndPassword(auth, loginState.email, loginState.password)
         .then(({user}) => {
            onLogIn(fromPage)
            document.body.style.overflow = "auto"; // Разрешение прокрутки экрана
         })
         .catch((error) => {
            if (error.message === 'Firebase: Error (auth/user-not-found).' || error.message === 'Firebase: Error (auth/wrong-password).') {
               setCurrentError('Неверный e-mail или пароль. Попробуйте еще раз.')
            }
         })
   }

   // При каждом изменении в поле ввода email или password - проверка на корректность ввода
   useEffect(() => {
      const emailValidity = loginState.email.match(/^[\w-\d*\.]+@[\w\d]+(\.\w{2,4})$/);
      // const passwordValidity = loginState.password.match(/(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/g);
      const passwordValidity = loginState.password.match(/(?=.*[0-9])(?=.*[\w-])[0-9a-zа-яA-ZА-Я!@#$%^&*]{6,}/g);

      if (!(loginState.email === '' && loginState.password === '')) {
         emailValidity ? setErrorMessageEmail("") : setErrorMessageEmail("Поле должно содержать e-mail")
         passwordValidity ? setErrorMessagePassword("") : setErrorMessagePassword("Пароль должен быть длиной не менее 6 символов и содержать цифры и буквы")
         setIsValid(emailValidity && passwordValidity);
      }
   }, [loginState.email, loginState.password])

   // При установке фокуса на поле ввода снятие readOnly
   // Первоначально readOnly={true}. Реализовано для того, чтобы не было автозаполнения полей ввода
   const handlerOnFocus = (e) => {
      if (isDesktop) {
         e.target.readOnly = false
      }
   }

   useEffect(() => {
      document.body.style.paddingRight = '0';
   },[])

   return (
      <div className={styles.login}>
         <div className={styles.login__block}>
            <form action="#"
                  onSubmit={handleSubmit}
                  className={styles.login__form}
                  name='login' noValidate
                  autoComplete="off">
               <h2 className={styles.login__title}>Да пребудет с тобой сила!</h2>
               <p className={styles.login__name}>E-mail</p>
               <input type="text"
                      readOnly={isDesktop}
                      onFocus={handlerOnFocus}
                      value={loginState.email}
                      onChange={handleChange}
                      className={styles.login__input}
                      name="email"
                      placeholder="введите е-mail"
                      minLength="2"
                      maxLength="40" required/>
               <p className={styles.login__inputError}>{errorMessageEmail}</p>
               <p className={styles.login__name}>Пароль</p>
               <input type="password"
                      onFocus={handlerOnFocus}
                      value={loginState.password}
                      onChange={handleChange}
                      className={styles.login__input}
                      name="password"
                      placeholder="введите пароль"
                      minLength="6"
                      maxLength="20" required/>
               <p className={styles.login__inputError}>{errorMessagePassword}</p>
               <p className={styles.login__error}>{currentError}</p>
               <button type="submit"
                       aria-label="submit"
                       className={isValid ? `${styles.login__submit}`
                          : `${styles.login__submit} ${styles.login__submit_disabled}`}
                       disabled={!isValid}
                       name="form_submit">
                  Войти
               </button>
               <p className={styles.login__name}>Ещё не зарегистрированы?&nbsp;
                  <Link className={styles.login__link} to='/registration'>Регистрация</Link>
               </p>

               <Link className={styles.login__link} to='/resetpassword'>Забыли пароль?</Link>
            </form>
         </div>

         <div className={styles.login__image}>
            <img src={Front1} alt="" className={styles.login__topImage}/>
            <img src={Front2} alt="" className={styles.login__bottomImage}/>
         </div>

      </div>
   );
}

export default Login;