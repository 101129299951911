import React from 'react';
import styles from './NotFound.module.scss'
import {Link} from "react-router-dom";

function NotFound(props) {
   return (
      <div className={styles.notfound}>
         <Link to="/">
            <h1 className={styles.notfound__title}>Страница не найдена</h1>
         </Link>
      </div>
   );
}

export default NotFound;