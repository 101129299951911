import {Link, useMatch} from 'react-router-dom';
import styles from "./SidebarLink.module.scss";

const SidebarLink = ({children, to,}) => {

   const match = useMatch({
      path: to,
      end: to.length === 1,
   });

   return (
         <Link to={to}
            className={match ? styles.activeLink : styles.nonactiveLink}>
            {children}
         </Link>
   )
}

export {SidebarLink};