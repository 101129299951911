import React, {useEffect, useState} from 'react';
import styles from "./Input.module.scss";
import Pencil from "../../../image/pencil.svg";
import Plus from "../../../image/plus.png";
import Minus from "../../../image/minus.png";
import {deleteItemFromArray, pushItemToArray} from "../../../utils/utility";

function Input({inputValue, changeValue, inputName, buttonEdit, isArray, inputType = 'text'}) {

   const [inputArray, setInputArray] = useState([''])
   const [inputCurrentValue, setInputCurrentValue] = useState(inputValue || '')

   const [isReady, setIsReady] = useState(true)

   const handlerOnChange = (evt) => {
      const {name, value, type} = evt.target;
      if (type === 'number') {
         changeValue(inputName, Number(value) != 0 ? Number(value) : null)
      } else {
         changeValue(inputName, value)
      }
   }

   const handlerOnChangeArray = (evt) => {
      const {name, value, type} = evt.target;
      // Создание промежуточного массива с учетом изменений
      const newArray = inputArray.map((item, index) => {
         if (index === Number(name)) {
            if (type === 'number') {
               return Number(value)
            } else {
               return value
            }
         } else {
            return item
         }
      })
      // Сохранение промежуточного массива в стейт
      setInputArray(newArray)
      // Сохранение в объект для записи в базу (с удалением пустых строк)
      changeValue(inputName, newArray.filter((item) => item.trim().length > 0))
   }

   const handlerPushItemToArray = (index) => {
      setIsReady(false)
      pushItemToArray(inputArray, index, '')
         .then((res) => {
            setInputArray(res)
            setIsReady(true)
         })
   }

   const handlerDeleteItemFromArray = (index) => {

      // TODO Не удалять последний элемент один должен быть всегда

      if (inputArray.length > 1) {
         setIsReady(false)
         deleteItemFromArray(inputArray, index)
            .then((res) => {
               setInputArray(res)
               // Сохранение в объект для записи в базу (с удалением пустых строк)
               changeValue(inputName, res.filter((item) => item.trim().length > 0))
               setIsReady(true)
            })
      }
      else {
         setInputArray([''])
         changeValue(inputName, null)
         setIsReady(true)
      }

   }

   // const clearInput = (evt) => {
   //    console.log('clearInput')
   //    changeValue(inputName, null)
   //    document.getElementById("input").value = 'gggg'
   // }

   useEffect(() => {
      // Если массив не пуст (вызов поля на редактирование)
      if (isArray) {
         if (inputValue && inputValue.length > 0) {
            setInputArray(inputValue)
         } else {
            setInputArray([''])
         }
      }
   }, []);

   if (!isArray) {
      // Не массив
      return (
         <label className={styles.inputBlock}>
            <input name={inputName}
                   defaultValue={inputValue || ''}
                   type={inputType}
                   className={styles.inputBlock__input}
                   onChange={handlerOnChange}
                   id='input'
            />
            {/*<img className={styles.inputBlock__imgButton}*/}
            {/*     src={Minus}*/}
            {/*     alt=""/>*/}
            {/*<img className={buttonEdit ? `${styles.inputBlock__imgPencil}` : `${styles.inputBlock__imgPencil_none}`}*/}
            {/*     src={Pencil}*/}
            {/*     alt=""/>*/}
         </label>
      );
   } else {
      // Массив
      return (
         isReady &&
         <div className={styles.blockArray}>
            {inputArray.map((item, index) => (
               <label className={styles.inputBlock} key={index}>
                  <input name={index.toString()}
                         defaultValue={item}
                         type={inputType}
                         className={styles.inputBlock__input}
                         onChange={handlerOnChangeArray}
                  />
                  <img className={styles.inputBlock__imgButton}
                       onClick={() => handlerPushItemToArray(index + 1)}
                       src={Plus}
                       alt=""/>
                  <img className={styles.inputBlock__imgButton}
                       onClick={() => handlerDeleteItemFromArray(index)}
                       src={Minus}
                       alt=""/>
               </label>
            ))}
         </div>
      );
   }

}

export default Input;