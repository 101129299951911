import React, {useEffect, useState} from 'react';
import styles from "./Select.module.scss";
import Clip from "../../../image/clip.svg";
import {useDispatch, useSelector} from "react-redux";
import {sortArrayObjectsByField} from "../../../utils/utility";
import {getCardCollection} from "../../../utils/cardFunction";
import {addDataCollection} from "../../../store/dataCollection";
import {CONFIG_DATA_COLLECTION} from "../../../utils/config";
import Minus from "../../../image/minus.png";

function Select({
                   textHeadSelect,
                   inputName,
                   saveSelectItems,
                   nameBaseSelect,
                   typeSelector,
                   viewField,
                   multiple,
                   array,
                   defaultValue,
                }) {

   const dispatch = useDispatch();

   document.body.style.overflow = "auto"
   const select = useSelector(state => state.data[CONFIG_DATA_COLLECTION[nameBaseSelect].collection]) // получение массива для селектора
   const [arraySelect, setArraySelect] = useState(null)
   const [isSelectVisible, setIsSelectVisible] = useState(false)
   const [selectedItem, setSelectedItem] = useState(textHeadSelect)
   const [isReady, setIsReady] = useState(false)

   let newSelect = null

   const arrayToSelect = (select) => {
      if (typeSelector === 'base' && select) {
         // если тип выборки "коллекция в базе"
         newSelect = select.map(item => {
            let selectedItem = false
            if (Array.isArray(defaultValue)) { // Проверка на наличие уже ранее выбранных позиций и пометка их если найдены
               if (defaultValue.find(data => data.pk === item.pk)) {
                  selectedItem = true
               }
            }
            return {
               viewValue: item[viewField],
               checkValue: selectedItem,
               pk: item.pk,
            }
         })
         setArraySelect(sortArrayObjectsByField(newSelect, 'viewValue'))
      }

      if (typeSelector === 'classifier') {
         // если тип выборки из классификатора
         newSelect = select[viewField].map(item => {
            return {
               viewValue: item,
               checkValue: false,
               pk: item.pk,
            }
         })
         setArraySelect(sortArrayObjectsByField(newSelect, 'viewValue'))
      }
   }

   const handlerOnClickHead = () => {
      setIsSelectVisible(!isSelectVisible)
   }

   const isArray = (item) => {
      // проверка будет ли одиночный выбор как массив или как текст
      let valueToSave = null
      if (array) {
         valueToSave = {
            name: item.viewValue,
            pk: item.pk,
         }
      } else {
         valueToSave = item.viewValue
      }
      return valueToSave
   }

   const clearSelect = () => {
      setSelectedItem(textHeadSelect)
      saveSelectItems(inputName, null)
   }

   const handlerOnClickItems = (item, indexOfArray) => {

      if (!multiple) {
         // Если не множественный выбор
         setSelectedItem(item.viewValue)
         saveSelectItems(inputName, array ? [isArray(item)] : isArray(item))
      } else {
         // Если множественный выбор
         const checkedItems = arraySelect.map((item, index) => {
            // выбор/снятие выбора
            if (index === indexOfArray) {
               return {...item, checkValue: !item.checkValue};
            } else {
               return item;
            }
         })

         setArraySelect(arraySelect.map((item, index) => {
            // выбор/снятие выбора
            if (index === indexOfArray) {
               return {...item, checkValue: !item.checkValue};
            } else {
               return item;
            }
         }));
         // формирование массива выбранных значений для сохранения при множественном выборе
         const arrayToSave = checkedItems.filter((item) => item.checkValue === true).map(item => {
            return {name: item.viewValue, pk: item.pk}
         })
         saveSelectItems(inputName, arrayToSave)
         setSelectedItem('Выбрано элементов: ' + arrayToSave.length)
         setIsSelectVisible(prevState => !prevState)
      }
   }

   const handlerOnChange = (evt) => {
      setIsSelectVisible(false)
      setIsReady(false)
      const {name, value, type} = evt.target;
      console.log(name, value)
      console.log(arraySelect)
      let arrayTemp = []
      setArraySelect(arraySelect.map((item) =>  {
         if (item.viewValue.includes(value)) {
            console.log(item)
            return item
         }
      }))




      // arraySelect.forEach((item) =>  {
      //    // console.log(item.viewValue)
      //    if (item.viewValue.includes(value)) {
      //       console.log(item.viewValue)
      //       arrayTemp.push({name: item.viewValue, pk: item.pk})
      //    }

         // item.viewValue.includes(value)).map(item =>
         // console.log(item.viewValue, value)
         // return {name: item.viewValue, pk: item.pk}
      // setArraySelect(arrayTemp)
      console.log(arraySelect)
      setIsSelectVisible(true)
      setIsReady(true)



   }

   useEffect(() => {
      // Проверка если поле не пусто (вызов на редактирование)
      if (defaultValue) {
         if (defaultValue[0] && typeof defaultValue[0] === "object") {
            setSelectedItem(defaultValue[0].name)
         } else {
            setSelectedItem(defaultValue)
         }
      }

      setIsReady(false)
      // проверка есть ли данные в Redux, если нет - запрос из базы данных и запись в Redux
      if (select.length === 0) {
         getCardCollection(nameBaseSelect) // Получение коллекции карточек
            .then((res) => {
               arrayToSelect(res)
               setIsReady(true)
               dispatch(addDataCollection({
                  collection: [nameBaseSelect].collection,
                  data: res,
               }))

            })
      } else {
         arrayToSelect(select)
         setIsReady(true)
      }
   }, []);


   return (
      arraySelect && isReady &&
      <div className={styles.select}>
         <div className={styles.select__head}
              onClick={handlerOnClickHead}>
            <img className={`${styles.select__img} ${styles.select__img_clip}`}
                 src={Clip}
                 alt=""
                 onClick={handlerOnClickHead}
            />
            <input className={styles.select__input}
                   placeholder={selectedItem.toString()}
                   type="text"
                   defaultValue={''}
                   // onChange={handlerOnChange}
                   id='input'
            />
            <img className={styles.inputBlock__imgButton}
                 onClick={clearSelect}
                 src={Minus}
                 alt=""/>
         </div>
         <ul className={isSelectVisible
            ? `${styles.select__list}`
            : `${styles.select__list} ${styles.select__list_none}`}>
            {arraySelect.map((item, index) => (
               <li key={index}
                   className={!item.checkValue ? `${styles.select__item}` : `${styles.select__item} ${styles.select__item_check}`}
                   onClick={() => handlerOnClickItems(item, index)}
               >
                  <p className={styles.select__text}>{item.viewValue}</p>
               </li>
            ))}
         </ul>
      </div>
   );

}

export default Select;