import {initializeApp} from "firebase/app";
import {
   getFirestore,
   collection,
   getDocs,
   setDoc,
   updateDoc,
   query,
   where,
   doc
} from 'firebase/firestore';

import {getDownloadURL, getStorage, ref} from "firebase/storage";

class FairebaseApi {
   constructor(db, getDocs, collection, storage, ref, getDownloadURL) {
      this.db = db;
      this.storage = storage;

      this.getDocs = getDocs
      this.ref = ref
      this.getDownloadURL = getDownloadURL;
   }

   // Получение коллекции, collection - имя получаемой коллекции
   getCollection(collectionName) {
      // console.log(collectionName)
      let data = [];
      return this.getDocs(collection(db, collectionName))
         .then((snapshot) => {
            snapshot.docs.forEach((doc) => {
               data.push({...doc.data(), id: doc.id})
            })
            // console.log(data)
            return data;
         })
         .catch((err) => console.log("Ошибка получения данных", err))
   }

   // Получение файла из хранилища, file - url получаемого файла
   getUrlFile(collectionName, file) {
      const pathRef = this.ref(this.storage, collectionName + '/' + file);
      return this.getDownloadURL(pathRef)
         .then((res) => {
            return res;
         })
   }

   // Получение документа из коллекции
   // collectionName - имя коллекции, field - наименование поля по какому ищется, cont - значение поля
   getDocument(collectionName, field, cont) {
      // console.log(collectionName, field, cont)

      const data = query(collection(db, collectionName), where(field, "==", cont));
      let response = {
         currentDocumentId: '',
         currentDocumentData: [],
      };
      return getDocs(data)
         .then((res) => {
            res.forEach((doc) => {
               response.currentDocumentId = doc.id
               response.currentDocumentData.push({...doc.data()})
               return response
            });
            return response
         })
         .catch((err) => {
            console.log("Ошибка получения данных", err)
            return err
         });
   }

   // Получение массива объектов [{name: , pk:}] из получаемого массива ссылок [1, 2, 3, ...]
   // collectionName - наименование коллекции на которую ссылки; data - массив ссылок [1, 2, 3, ...]
   // возвращаемое значение - массив объектов [{name: , pk:}]
   getArrayLinks(collectionName, data) {
      let arrayLink = [];
      data.forEach((item) => {
         this.getDocument(collectionName, "pk", item)
            .then((res) => {
               arrayLink.push({name: res[0].name, pk: res[0].pk})
            })
            .catch((error) => {
               console.log(`Ошибка загрузки ${collection}`, error.code)
            })
      })
      return arrayLink
   }

   // Добавление нового документа в коллекцию
   addDocToCollection(collectionName, data) {
      // console.log(collectionName, data)
      const newDocRef = doc(collection(db, collectionName));
      return setDoc(newDocRef, data)
         .then((res) => {
            return {
               id: newDocRef.id,
               data: {...data},
               createTimestamp: new Date(),
            }
         })
         .catch((error) => {
            console.log(`Ошибка добавления документа ${collection}`, error.code)
         })
   }

   updateDocToCollection(collectionName, data, id) {
      // console.log(collectionName, data, id)
      const docRef = doc(db, collectionName, id);
      return updateDoc(docRef, data)
         .then((res) => {
               console.log("Entire Document has been updated successfully")
         })
         .catch((error) => {
            console.log(`Ошибка обновления документа ${collection}`, error.code)
         })
   }

}

const firebaseConfig = {
   apiKey: "AIzaSyDoTO2lQQTfh5k1wmSyc-okZm9lu-8hvEA",
   authDomain: "serkms-star-wars.firebaseapp.com",
   projectId: "serkms-star-wars",
   storageBucket: "serkms-star-wars.appspot.com",
   messagingSenderId: "462356558796",
   appId: "1:462356558796:web:158c9d098fdbc47565e369"
   // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
   // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
   // projectId:process.env.REACT_APP_FIREBASE_PROJECT_ID,
   // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
   // messagingSenderId:process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
   // appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

initializeApp(firebaseConfig)

const db = getFirestore();

const storage = getStorage();

const fairebaseApi = new FairebaseApi(db, getDocs, collection, storage, ref, getDownloadURL);
export default fairebaseApi;