import React, {useEffect, useState} from 'react';
import styles from "./TextArea.module.scss";
import Pencil from "../../../image/pencil.svg";
import Plus from "../../../image/plus.png";
import Minus from "../../../image/minus.png";
import {deleteItemFromArray, pushItemToArray} from "../../../utils/utility";

function TextArea({inputValue, changeValue, inputName, buttonEdit, isArray, deleteString}) {

   const [currentTextAreaItem, setCurrentTextAreaItem] = useState(null)
   const [inputArray, setInputArray] = useState([{
      text: '',
      rows: 1,
   }])
   const [isReady, setIsReady] = useState(true)

   const handlerPushItemToArray = (index) => {
      setIsReady(false)
      pushItemToArray(inputArray, index, {text: '', rows: 1})
         .then((res) => {
            setInputArray(res)
            setIsReady(true)
         })
   }

   const handlerDeleteItemFromArray = (index) => {
      if (inputArray.length > 1) {
         setIsReady(false)
         deleteItemFromArray(inputArray, index)
            .then((res) => {
               // Сохранение массива в стейт
               setInputArray(res)
               deleteString(res)
               setIsReady(true)
            })
      }
   }

   const countRowsCurrentTextArea = (currentTextArea) => {
      // Определение количества строк занимаемых текстовым полем и установление высоты текстового поля
      if ((currentTextArea.scrollHeight - currentTextArea.offsetHeight) > 0) {
         // Цикл пока высота текста больше высоты скролла
         while ((currentTextArea.scrollHeight - currentTextArea.offsetHeight) > 0) { // выводит 0, затем 1, затем 2
            currentTextArea.rows = currentTextArea.rows + 1
         }
      }
   }

   const handlerOnChange = (evt) => {
      const currentTextArea = document.getElementById('text-area' + inputName)
      const {name, value} = evt.target;
      changeValue(name, value)
      countRowsCurrentTextArea(currentTextArea)
      // if ((currentTextArea.scrollHeight - currentTextArea.offsetHeight) > 0) {
      //    // Цикл пока высота текста больше высоты скролла
      //    while ((currentTextArea.scrollHeight - currentTextArea.offsetHeight) > 0) { // выводит 0, затем 1, затем 2
      //       currentTextArea.rows = currentTextArea.rows + 1
      //    }
      // }
   }

   const handlerOnChangeArray = (evt) => {
      countRowsCurrentTextArea(currentTextAreaItem)
      // const currentTextArea = currentTextAreaItem
      // if ((currentTextArea.scrollHeight - currentTextArea.offsetHeight) > 0) {
      //    // Цикл пока высота текста больше высоты скролла
      //    while ((currentTextArea.scrollHeight - currentTextArea.offsetHeight) > 0) { // выводит 0, затем 1, затем 2
      //       currentTextArea.rows = currentTextArea.rows + 1
      //    }
      // }
   }

   const handlerOutFocus = (evt) => {
      const {name, value, rows} = evt.target;
      // Создание промежуточного массива с учетом изменений
      const newArray = inputArray.map((item, index) => {
         if (index === Number(name)) {
            return {text: value, rows: Number(rows)}
         } else {
            return item
         }
      })
      // Сохранение промежуточного массива в стейт
      setInputArray(newArray)
      // Сохранение в объект для записи в базу
      changeValue(inputName, newArray)
   }

   const handlerOnFocusTextAreaItem = (evt) => {
      const {name} = evt.target;
      setCurrentTextAreaItem(document.getElementById('text-area' + name.toString()))
      countRowsCurrentTextArea(document.getElementById('text-area' + name.toString()))
   }

   useEffect(() => {
      if (inputValue) { // Если поле не пустое
         if (typeof inputValue !== "object") { // Если не массив
            setCurrentTextAreaItem(inputValue)
            const currentTextArea = document.getElementById('text-area' + inputName)
            countRowsCurrentTextArea(currentTextArea)
         } else { // Если массив
            setInputArray(inputValue)
         }
      }
   }, []);

   if (!isArray) {
      return (
         <label className={styles.inputBlock}>

            <textarea name={inputName}
                      className={styles.inputBlock__input}
                      readOnly={false}
                      defaultValue={inputValue || ''}
                      rows={1}
                      onChange={handlerOnChange}
                      id={'text-area' + inputName}
            />

            <img className={buttonEdit ? `${styles.inputBlock__imgButton}` : `${styles.inputBlock__imgButton_none}`}
                 src={Pencil}
                 alt=""/>

         </label>
      );
   } else {
      return (
         isReady && inputArray &&
         <div className={styles.blockArray}>
            {inputArray.map((item, index) => (
               <label className={styles.inputBlock} key={index}>
                  <textarea name={index.toString()}
                            onFocus={handlerOnFocusTextAreaItem}
                            className={styles.inputBlock__input}
                            readOnly={false}
                            defaultValue={item.text}
                            rows={item.rows || 1}
                            onChange={handlerOnChangeArray}
                            onBlur={handlerOutFocus}
                            id={'text-area' + index.toString()}
                  />

                  <img className={styles.inputBlock__imgButton}
                       onClick={() => handlerPushItemToArray(index + 1)}
                       src={Plus}
                       alt=""/>
                  <img className={styles.inputBlock__imgButton}
                       onClick={() => handlerDeleteItemFromArray(index)}
                       src={Minus}
                       alt=""/>

               </label>

            ))}
         </div>
      );

   }
}

export default TextArea;