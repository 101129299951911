// Возвращает ширину полосы прокрутки
import fairebaseApi from "./FairebaseApi";
import {CONFIG_DATA_COLLECTION, USER_DATA} from "./config";
import {uploadFileToFirebaseStorage} from './firebaseUtility'
import {useSelector} from "react-redux";

//--------------------------------------------------------------------------------
// Проверка на наличие скролла
//--------------------------------------------------------------------------------
export const countScrollWidth = () => {
   let div = document.createElement('div');
   div.style.overflowY = 'scroll';
   div.style.width = '50px';
   div.style.height = '50px';
   document.body.append(div);
   let scrollWidth = div.offsetWidth - div.clientWidth;
   div.remove();
   return scrollWidth
}

//--------------------------------------------------------------------------------
// Возвращает image файл в формате base64
//--------------------------------------------------------------------------------
export function getImageFileFormatBase64(file) {
   return new Promise(function (resolve) {
      const reader = new FileReader();
      reader.onload = function () {
         resolve(reader.result);
      };
      reader.onerror = function (error) {
         resolve(null);
      };
      reader.readAsDataURL(file);
   });
}

//--------------------------------------------------------------------------------
// Возвращает размер image файл формата base64
//--------------------------------------------------------------------------------
export function getImageFileFormatBase64Size(file) {
   return new Promise(function (resolve) {
      let img = new Image();
      img.src = file;
      img.onload = function () {
         resolve({
            widthUploadImage: this.width,
            heightUploadImage: this.height,
         });
      };
      img.onerror = function (error) {
         resolve(null);
      };
   });
}

//--------------------------------------------------------------------------------
// Получение данных текущего пользователя
//--------------------------------------------------------------------------------
export function checkCurrentUserDocument(email, uid) {
   return new Promise(function (resolve) {
      fairebaseApi.getDocument('dataUsers', 'email', email)
         .then((res) => {
            if (res.currentDocumentData.length > 0) {
               console.log('Данные получены')
               resolve({
                  isProfileExist: true,
                  profileData: res,
               })
            } else {
               console.log('Данных нет')
               let user_data = USER_DATA;
               user_data.email = email;
               user_data.user_auth_id = uid;
               resolve({
                  isProfileExist: false,
                  profileData: user_data,
               })
            }
         })
   })
}


//--------------------------------------------------------------------------------
// Обновление аватара текущего юзера
//--------------------------------------------------------------------------------
export function updateAvatarCurrentUser(email, avatarUrl) {
   const data = {
      avatar_url: avatarUrl,
   }
   fairebaseApi.getDocument('dataUsers', 'email', email)
      .then((res) => {
         if (res.currentDocumentData.length > 0) {
            console.log('Пользователь найден', res.currentDocumentId)
            fairebaseApi.updateDocToCollection('dataUsers', data, res.currentDocumentId)
               .then((res) => {
               })
         } else {
            console.log('Данных нет')
         }
      })
}

export const getUserFromSessionStorage = () => {
   return JSON.parse(sessionStorage.getItem('currentUser'))
}

export const setUserToSessionStorage = (user) => {
   sessionStorage.setItem('currentUser', JSON.stringify(user));
}

export const removeUserFromSessionStorage = () => {
   sessionStorage.removeItem('currentUser');
}

//--------------------------------------------------------------------------------
// Сортировка массива объектов по полю в объекте
//--------------------------------------------------------------------------------
export const sortArrayObjectsByField = (sortArray, field) => {
   return sortArray.sort(function (a, b) {
      if (a[field] > b[field]) {
         return 1;
      }
      if (a[field] < b[field]) {
         return -1;
      }
      return 0;
   });
}

//--------------------------------------------------------------------------------
// Добавление элемента в массив
//--------------------------------------------------------------------------------
export function pushItemToArray(currentArray, index, data) {
   return new Promise(function (resolve) {
      currentArray.splice(index, 0, data)
      resolve(currentArray);
   });
}

//--------------------------------------------------------------------------------
// Удаление элемента из массива
//--------------------------------------------------------------------------------
export function deleteItemFromArray(currentArray, index) {
   // console.log(index, currentArray)
   return new Promise(function (resolve) {
      currentArray.splice(index, 1)
      resolve(currentArray);
   });
}

export function getDataFromRedux(collectionName, viewField, returnField) {
   return new Promise(function (resolve) {
      const select = useSelector(state => state.data[collectionName]).map(item => {
         return {
            viewValue: item[viewField],
            returnValue: item[returnField],
            checkValue: false,
         }
      })
      resolve(select);
   });
}

//--------------------------------------------------------------------------------
// Запись в хранилище аватара новой карточки
//--------------------------------------------------------------------------------
export function addCardAvatarToStorage(cardAvatar, collection) {
   return new Promise(function (resolve) {
      if (!cardAvatar) {
         resolve('')
      }
      uploadFileToFirebaseStorage(CONFIG_DATA_COLLECTION[collection].avatar_directory + '/', cardAvatar.avatarImageFile, cardAvatar.avatarImageFileName)
         .then((res) => {
            resolve(res);
         })
         .catch((err) => {
            console.log('ошибка сохранения')
            resolve('')
         })
   })
}

//--------------------------------------------------------------------------------
// Добавление в коллекцию новой карточки
//--------------------------------------------------------------------------------
export function addCardToCollection(cardData, cardDescription, collection) {
   return new Promise(function (resolve) {
      fairebaseApi.addDocToCollection(CONFIG_DATA_COLLECTION[collection].collection, cardData)
         .then((res) => {
            cardDescription && fairebaseApi.addDocToCollection(CONFIG_DATA_COLLECTION[collection].description, cardDescription)
               .then((res) => {
               })
         })
      resolve(cardData);
   })
}

export function addDescriptionToCollection(collectionName, cardDescription) {
   return new Promise(function (resolve) {
      fairebaseApi.addDocToCollection(collectionName, cardDescription)
         .then((res) => {
         })
      resolve(true);
   })
}