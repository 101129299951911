import React from 'react';
import {Link} from "react-router-dom";
import styles from './SentResetPassword.module.scss'
import Front1 from '../../image/front_1.png'
import Front2 from '../../image/front_2.png'

function SentResetPassword() {

   return (
      <div className={styles.sentReset}>
         <div className={styles.sentReset__block}>
            <h2 className={styles.sentReset__title}>Ссылка для сброса пароля направлена</h2>
            <h2 className={styles.sentReset__title}>на указанный e-male</h2>

            <p className={styles.sentReset__text}>Перейдите из полученного сообщения по ссылке для сброса пароля и зайдите на&nbsp;
               <Link className={styles.sentReset__link} to='/login'>страницу авторизации</Link>
               &nbsp;для входа
            </p>

         </div>

         <div className={styles.sentReset__image}>
            <img src={Front1} alt="" className={styles.sentReset__topImage}/>
            <img src={Front2} alt="" className={styles.sentReset__bottomImage}/>
         </div>

      </div>

   );
}

export default SentResetPassword;
