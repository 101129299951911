import React from "react";
import styles from "./UserProfileNotExist.module.scss";
import Image from "../../image/avatar-default.png";
import fairebaseApi from "../../utils/FairebaseApi";

function UserProfileNotExist({user_data, onCreateProfile}) {

   const createUserProfile = () => {
      fairebaseApi.addDocToCollection("dataUsers", user_data)
         .then((res) => {
            onCreateProfile()
         })
   }

   return (
      <div className={styles.block}>
         <img src={Image} alt=""x
              className={styles.block__image}/>
         <h1 className={styles.block__title}>Профиль пользователя еще не создан</h1>

         <div className={styles.buttonArea}>
            <button type="button"
                    className={styles.buttonArea__button}
                    onClick={createUserProfile}>
               Создать профиль
            </button>
         </div>

      </div>
   );
}

export default UserProfileNotExist;
