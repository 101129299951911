import React, {useEffect, useState} from 'react';
import styles from "./FilmInfo.module.scss";
import {useParams} from "react-router-dom";
import DefaultAvatar from "../../image/avatar-default.png";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import InfoDescription from "../CardFields/InfoDescription/InfoDescription";
import {CARD_INFO_CONFIG} from "../../utils/config";
import {getCardDescription, getCrumbsCardInfo, getDocumentFromCollection} from "../../utils/cardFunction";
import FieldText from "../CardFields/FieldText/FieldText";

function FilmInfo() {

   const {id} = useParams();
   const [currentCard, setCurrentCard] = useState(null)
   const [crumbs, setCrumbs] = useState(null)
   const [cardDescription, setCardDescription] = useState(null)
   const [isReady, setIsReady] = useState(false)

   const [isContentBlockVisible, setIsContentBlockVisible] = useState(false)
   const [isDescriptionBlockVisible, setIsDescriptionBlockVisible] = useState(false)

   const handlerVisibleContentBlock = () => {
      setIsContentBlockVisible(!isContentBlockVisible)
   }

   const handlerVisibleDescriptionBlock = () => {
      setIsDescriptionBlockVisible(!isDescriptionBlockVisible)
   }

   useEffect(() => {
      getDocumentFromCollection('films', 'pk', id)
         .then((res) => {
            setCrumbs(getCrumbsCardInfo('films', res.cardData.name))
            setCurrentCard(res.cardData)
            getCardDescription('films', 'pk', id)
               .then((res) => {
                  setCardDescription(res.cardData)
                  setIsReady(true)
               })
         })
      window.scrollTo(0, 0)
   }, [])

   return (
      isReady &&
      <div className={styles.filmInfo}>
         <BreadCrumbs crumbs={crumbs}/>
         <div className={styles.filmInfo__mainInfo}>
            <img src={currentCard.avatar_url ? currentCard.avatar_url : DefaultAvatar} alt=""
                 className={`${styles.filmInfo__image} ${styles.filmInfo__image_big}`}/>

            <img src={currentCard.avatar_url_min ? currentCard.avatar_url_min : DefaultAvatar} alt=""
                 className={`${styles.filmInfo__image} ${styles.filmInfo__image_min}`}/>

            <ul className={styles.filmInfo__content}>
               {
                  CARD_INFO_CONFIG.films.content.map((item, index) =>
                     <FieldText key={index}
                                nameField={item.name}
                                text={currentCard[item.field_name]}
                                posfix={item.postfix || ''}
                     />
                  )
               }
            </ul>
         </div>

         {
            // Проверка на наличие краткого содержания фильма
            cardDescription && cardDescription.content
               ?
               <div>
                  <p className={styles.filmInfo__descriptionBlock}
                     onClick={handlerVisibleContentBlock}>Краткое содержание
                     <span
                        className={!isContentBlockVisible ? `${styles.filmInfo__triangleRight}` : `${styles.filmInfo__triangleNone}`}>
                     &nbsp;&#9658;
                  </span>
                     <span
                        className={isContentBlockVisible ? `${styles.filmInfo__triangleDown}` : `${styles.filmInfo__triangleNone}`}>
                     &nbsp;&#9660;
                  </span>
                  </p>
                  <div
                     className={isContentBlockVisible ? `${styles.filmInfo__description}` : `${styles.filmInfo__description} ${styles.filmInfo__description_hide}`}>
                     <InfoDescription content={cardDescription.content}/>
                  </div>
               </div>
               :
               <div></div>
         }

         {
            // Проверка на наличие информации о фильме
            cardDescription && cardDescription.description
               ?
               <div>
                  <p className={styles.filmInfo__descriptionBlock}
                     onClick={handlerVisibleDescriptionBlock}>О фильме ...
                     <span
                        className={!isDescriptionBlockVisible ? `${styles.filmInfo__triangleRight}` : `${styles.filmInfo__triangleNone}`}>
                     &nbsp;&#9658;
                  </span>
                     <span
                        className={isDescriptionBlockVisible ? `${styles.filmInfo__triangleDown}` : `${styles.filmInfo__triangleNone}`}>
                     &nbsp;&#9660;
                  </span>
                  </p>
                  <div
                     className={isDescriptionBlockVisible ? `${styles.filmInfo__description}` : `${styles.filmInfo__description} ${styles.filmInfo__description_hide}`}>
                     <InfoDescription content={cardDescription.description}/>
                  </div>
               </div>
               :
               <div></div>
         }
      </div>

   );

}

export default FilmInfo;