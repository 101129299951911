import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

const dataUsers = createSlice({
   name: 'user',
   initialState: {
      currentUser: {},
      userID: null,
      userData: null,
   },

   reducers: {
      addAuth(state, action) {
         state.currentUser = action.payload.user
      },

      resetAuth(state, action) {
         state.currentUser = {}
      },

      addUserData(state, action) {
         state.userData = action.payload.dataUser
         state.userID = action.payload.userID
      },
   },
   // extraReducers: (builder) => {
   //    builder
   //       .addCase(getDataFilms.pending, (state) => {
   //          state.status = 'loading';
   //          state.error = null;
   //          state.isDataFilms = false
   //       })
   //
   //       .addCase(getDataFilms.fulfilled, (state, action) => {
   //          state.status = 'resolved';
   //          state.error = null;
   //          state.dataFilms = action.payload.collectionData
   //          state.isDataFilms = true
   //       })
   //       .addCase(getDataFilms.rejected, (state) => {
   //          state.status = 'rejected';
   //          state.error = true;
   //          state.isDataFilms = false
   //       })
   // }

});

// export const {addAuth} = dataUsers.reducer

// export default dataUsers.reducer;

export const {addAuth, resetAuth, addUserData} = dataUsers.actions;

export default dataUsers.reducer;