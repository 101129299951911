import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import fairebaseApi from "../utils/FairebaseApi";
// import {getCardCollection, getCrumbs} from "../utils/cardFunction";
// import {sortArrayObjectsByField} from "../utils/utility";
// import {CONFIG_DATA_COLLECTION} from "../utils/config";

export const getDataFilms = createAsyncThunk(
   'data/getDataFilms',
   async () => {
      const data = await fairebaseApi.getCollection('dataFilms');
      const response = {
         collectionData: data,
      }
      return response
   }
)

export const getDataConfig = createAsyncThunk(
   'data/getDataConfig',
   async () => {
      const data = await fairebaseApi.getCollection('dataConfig');
      const response = {
         collectionData: data,
      }
      return response
   }
)

export const getDataPlanets = createAsyncThunk(
   'data/getDataPlanets',
   async () => {
      const data = await fairebaseApi.getCollection('dataPlanets');
      const response = {
         collectionData: data,
      }
      return response
   }
)

export const getDataSpecies = createAsyncThunk(
   'data/getDataSpecies',
   async () => {
      const data = await fairebaseApi.getCollection('dataSpecies');
      const response = {
         collectionData: data,
      }
      return response
   }
)

export const getDataPeople = createAsyncThunk(
   'data/getDataPeople',
   async () => {
      const data = await fairebaseApi.getCollection('dataPeople');
      const response = {
         collectionData: data,
      }
      return response
   }
)

export const getDataStarships = createAsyncThunk(
   'data/getDataStarships',
   async () => {
      const data = await fairebaseApi.getCollection('dataStarships');
      const response = {
         collectionData: data,
      }
      return response
   }
)

export const getDataVehicles = createAsyncThunk(
   'data/getDataVehicles',
   async () => {
      const data = await fairebaseApi.getCollection('dataVehicles');
      const response = {
         collectionData: data,
      }
      return response
   }
)

const dataCollection = createSlice({
   name: 'data',
   initialState: {
      dataConfig: {},
      dataPlanets: [],
      dataFilms: [],
      dataSpecies: [],
      dataPeople: [],
      dataStarships: [],
      dataVehicles: [],
      dataSettings: [],
      isDataPlanets: false,
      isDataFilms: false,
      isDataSpecies: false,
      isDataPeople: false,
      isDataStarships: false,
      isDataVehicles: false,
      isDataSettings: false,
      status: null,
      error: null,
   },

   reducers: {
      addDataCollection(state, action) {
         state[action.payload.collection] = action.payload.data
      },

      addCardToDataCollection(state, action) {
         state[action.payload.collection] = [...state[action.payload.collection], action.payload.data]
      },
   },


   extraReducers: (builder) => {
      builder
         .addCase(getDataFilms.pending, (state) => {
            state.status = 'loading';
            state.error = null;
            state.isDataFilms = false
         })

         .addCase(getDataFilms.fulfilled, (state, action) => {
            state.status = 'resolved';
            state.error = null;
            state.dataFilms = action.payload.collectionData
            state.isDataFilms = true
         })
         .addCase(getDataFilms.rejected, (state) => {
            state.status = 'rejected';
            state.error = true;
            state.isDataFilms = false
         })

      // Получение данных из коллекции DataPlanets
      builder.addCase(getDataPlanets.pending, (state) => {
         state.status = 'loading';
         state.error = null;
         state.isDataPlanets = false
      })
         .addCase(getDataPlanets.fulfilled, (state, action) => {
            state.status = 'resolved';
            state.error = null;
            state.dataPlanets = action.payload.collectionData
            state.isDataPlanets = true
         })
         .addCase(getDataPlanets.rejected, (state) => {
            state.status = 'rejected';
            state.error = true;
            state.isDataPlanets = false
         })

      // Получение данных из коллекции DataSpecies
      builder.addCase(getDataSpecies.pending, (state) => {
         state.status = 'loading';
         state.error = null;
         state.isDataSpecies = false
      })
         .addCase(getDataSpecies.fulfilled, (state, action) => {
            state.status = 'resolved';
            state.error = null;
            state.dataSpecies = action.payload.collectionData
            state.isDataSpecies = true
         })
         .addCase(getDataSpecies.rejected, (state) => {
            state.status = 'rejected';
            state.error = true;
            state.isDataSpecies = false
         })

      // Получение данных из коллекции DataPeople
      builder.addCase(getDataPeople.pending, (state) => {
         state.status = 'loading';
         state.error = null;
         state.isDataPeople = false
      })
         .addCase(getDataPeople.fulfilled, (state, action) => {
            state.status = 'resolved';
            state.error = null;
            state.dataPeople = action.payload.collectionData
            state.isDataPeople = true

         })
         .addCase(getDataPeople.rejected, (state) => {
            state.status = 'rejected';
            state.error = true;
            state.isDataPeople = false
         })

      // Получение данных из коллекции DataPeople
      builder.addCase(getDataStarships.pending, (state) => {
         state.status = 'loading';
         state.error = null;
         state.isDataStarships = false
      })
         .addCase(getDataStarships.fulfilled, (state, action) => {
            state.status = 'resolved';
            state.error = null;
            state.dataStarships = action.payload.collectionData
            state.isDataStarships = true

         })
         .addCase(getDataStarships.rejected, (state) => {
            state.status = 'rejected';
            state.error = true;
            state.isDataStarships = false
         })

      // Получение данных из коллекции DataVehicles
      builder.addCase(getDataVehicles.pending, (state) => {
         state.status = 'loading';
         state.error = null;
         state.isDataVehicles = false
      })
         .addCase(getDataVehicles.fulfilled, (state, action) => {
            state.status = 'resolved';
            state.error = null;
            state.dataVehicles = action.payload.collectionData
            state.isDataVehicles = true

         })
         .addCase(getDataVehicles.rejected, (state) => {
            state.status = 'rejected';
            state.error = true;
            state.isDataVehicles = false
         })

      // Получение данных из коллекции DataConfig
      builder.addCase(getDataConfig.pending, (state) => {
         state.status = 'loading';
         state.error = null;
         state.isDataConfig = false
      })
         .addCase(getDataConfig.fulfilled, (state, action) => {
            state.status = 'resolved';
            state.error = null;
            state.dataConfig = action.payload.collectionData
            state.isDataConfig = true

         })
         .addCase(getDataConfig.rejected, (state) => {
            state.status = 'rejected';
            state.error = true;
            state.isDataConfig = false
         })
   }

});

export const {addDataCollection, addCardToDataCollection} = dataCollection.actions;

export default dataCollection.reducer;