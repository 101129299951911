import React, {useEffect, useState} from 'react';
import styles from './FieldLinks.module.scss'
import {Link} from "react-router-dom";
import ArrUp from '../../../image/arr-up.png'
import ArrDown from '../../../image/arr-down.png'

function FieldLinks({nameLinks, pathLinks, dataLinks}) {

   const [isVisibleAllArray, setIsVisibleAllArray] = useState(true)

   const handlerOnVisibleAllArray = () => {
      setIsVisibleAllArray(!isVisibleAllArray)
   }

   useEffect(() => {
      if (dataLinks.length > 5) {
         setIsVisibleAllArray(false)
      }
   }, [])

   return (
      <div className={styles.fieldLinks}>
         <p className={dataLinks.length > 5 ? `${styles.fieldLinks__name} ` : `${styles.fieldLinks__name} ${styles.fieldLinks_short}`}>
            {nameLinks}
            <span
               className={dataLinks.length <= 5
                  ? `${styles.fieldLinks_array_markColon}`
                  : `${styles.fieldLinks__text_none}`}> : </span>
         </p>
         <img src={ArrDown} alt="" className={dataLinks.length > 5 && !isVisibleAllArray
            ? `${styles.fieldLinks_array_markArrow}`
            : `${styles.fieldLinks__text_none}`} onClick={handlerOnVisibleAllArray}/>
         <img src={ArrUp} alt="" className={dataLinks.length > 5 && isVisibleAllArray
            ? `${styles.fieldLinks_array_markArrow}`
            : `${styles.fieldLinks__text_none}`} onClick={handlerOnVisibleAllArray}/>
         <ul className={styles.fieldLinks__array}>
            {dataLinks.map((item, index) => (
               <li key={index}>
                  <Link to={`/${pathLinks}/${item.pk}`}
                        className={(index > 4 && !isVisibleAllArray) ? `${styles.fieldLinks__text_clickable} ${styles.fieldLinks__text_none}` : `${styles.fieldLinks__text_clickable}`}>
                     {item.name}{index < dataLinks.length - 1 ? `,` : ``}&nbsp;
                  </Link>
               </li>
            ))}
            <p className={dataLinks.length > 5 && !isVisibleAllArray ? `${styles.fieldLinks__continue}` : `${styles.fieldLinks__text_none}` } >. . .</p>
         </ul>
         {/*<p className={dataLinks.length > 5 ? `${styles.fieldLinks_array_mark}` : `${styles.fieldLinks__text_none}`}>&nbsp;&#9658;</p>*/}
         {/*</div>*/}
      </div>
   );
}

export default FieldLinks;
