import {Navigate} from 'react-router-dom';
import {getUserFromSessionStorage} from "../../utils/utility";

const ProtectedRoute = ({children}) => {
    const isAuth = getUserFromSessionStorage()

    if (!isAuth) {
        return <Navigate to='/login' />
    }

    return children;
}

export {ProtectedRoute};