import React, {useState} from "react";
import styles from "./UserPageAvatar.module.scss";
import DefaultAvatar from "../../../image/avatar-default.png";
import UploadFile from "../../../image/upload.svg";
import Clip from "../../../image/clip.svg";
import {getImageFileFormatBase64, getImageFileFormatBase64Size, updateAvatarCurrentUser} from "../../../utils/utility";
import {updateAvatarToFirebase, uploadFileToFirebaseStorage} from "../../../utils/firebaseUtility";

function UserPageAvatar({avatarUrl, onChangeAvatar, userEmail}) {

   const [selectedAvatarFile, setSelectedAvatarFile] = useState({})
   const [isSelectAvatarFile, setIsSelectAvatarFile] = useState(false)
   const [isChangeBlockVisible, setIsChangeBlockVisible] = useState(false)
   const [errorMessages, setErrorMessages] = useState([]);

   // Обновление аватара
   const updateAvatar = (avatar) => {
      updateAvatarToFirebase(avatar)
         .then((res) => {
            sessionStorage.setItem('currentUser', JSON.stringify(res));
            const currentUserData = JSON.parse(sessionStorage.getItem('currentUser'))
            updateAvatarCurrentUser(currentUserData.email, res.photoURL)
            onChangeAvatar(res.photoURL)
         })
   }

   // Выбор файла аватара для загрузки на сервер и проверка на соответствие условиям
   const selectAvatarFile = (event) => {
      setErrorMessages([])
      if (event.target.files[0] && event.target.files.length > 0) {
         const avatarFileExtension = event.target.files[0].name.split(".")[1].toLowerCase()
         setSelectedAvatarFile({
            avatarImageFile: event.target.files[0],
            avatarImageFileName: userEmail + '.' + avatarFileExtension,
         })
         getImageFileFormatBase64(event.target.files[0])
            .then((res) => {
               getImageFileFormatBase64Size(res)
                  .then((res) => {
                     if (event.target.files[0].size > 100000) {
                        setErrorMessages(current => [...current, `- размер ${event.target.files[0].size / 1000} Кб`]);
                     }
                     if (avatarFileExtension !== 'png' && avatarFileExtension !== 'jpg' && avatarFileExtension !== 'jpeg') {
                        setErrorMessages(current => [...current, `- тип файла .${avatarFileExtension}`]);
                     }
                     if (res.heightUploadImage * res.widthUploadImage > 160000) {
                        console.log('Недопустимый размер файла')
                        setErrorMessages(current => [...current, `- размерность ${res.widthUploadImage}x${res.heightUploadImage}px`]);
                        setIsSelectAvatarFile(false)
                     } else {
                        setIsSelectAvatarFile(true)
                     }
                  })
            })
      }
   }

   // Загрузка файла аватара на сервер
   const uploadSelectedAvatarFile = () => {
      // Если файл выбран и нет ошибок
      if (selectedAvatarFile && errorMessages.length === 0) {
         uploadFileToFirebaseStorage('users-avatar/', selectedAvatarFile.avatarImageFile, selectedAvatarFile.avatarImageFileName)
            .then((res) => {
               updateAvatar(res)
               setIsSelectAvatarFile(false)
            })
      }
      document.getElementById("photo_input").value = ''
   }

   // Отображение блока для изменений в аватар
   const handlerVisibleChangeAvatarBlock = () => {
      setIsChangeBlockVisible(!isChangeBlockVisible)
      setIsSelectAvatarFile(null)
      setErrorMessages([])
      document.getElementById("photo_input").value = ''
   }

   return (
      <>
         {/*// TODO Сделать проверку если битая ссылка на изображение*/}
         <div className={styles.personInfo__blockAvatar}>

            <img src={avatarUrl ? avatarUrl : DefaultAvatar} alt=""
                 className={styles.personInfo__imageAvatar}/>

            <p className={styles.personInfo__linkChangeAvatar}
               onClick={handlerVisibleChangeAvatarBlock}>Изменить аватар
               <span
                  className={!isChangeBlockVisible ? `${styles.personInfo__triangleRight}` : `${styles.personInfo__triangleNone}`}>
                     &nbsp;&#9658;
                  </span>
               <span
                  className={isChangeBlockVisible ? `${styles.personInfo__triangleDown}` : `${styles.personInfo__triangleNone}`}>
                     &nbsp;&#9660;
                  </span>
            </p>

            <ul className={errorMessages.length > 0 ? `${styles.errorAvatar}` : `${styles.errorAvatar__hide}`}>
               <p className={styles.errorAvatar__errorText}>Несоответствие требованиям: </p>

               {errorMessages.map((item, index) => (
                  <p key={index} className={styles.errorAvatar__errorText}>{item} </p>
               ))}
            </ul>

            <div className={isChangeBlockVisible ? `${styles.change}` : `${styles.change} ${styles.change_hide}`}>
               <label className={styles.inputBlock}>
                  <img
                     className={styles.inputBlock__img}
                     src={Clip}
                     alt=""/>
                  <p className={styles.inputBlock__text}>{!isSelectAvatarFile ? 'Выберите файл' : 'Выбран 1 файл'}</p>
                  <input name='file' type="file" id="photo_input" onChange={selectAvatarFile}
                         className={styles.inputBlock__input}/>
               </label>
               <img
                  className={!isSelectAvatarFile
                     ? `${styles.change__uploadImg}`
                     : `${styles.change__uploadImg} ${styles.change__uploadImg_active}`}
                  src={UploadFile}
                  alt=""
                  onClick={uploadSelectedAvatarFile}/>
               <p className={styles.change__uploadText}>Выберите файл .png или .jpg, не более 100 Кб и
                  400х400px.</p>
            </div>

         </div>
      </>
   );
}

export default UserPageAvatar;
