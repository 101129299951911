import React, {useEffect} from 'react';
import styles from './Footer.module.scss'

function Footer() {

   useEffect(() => {
      sessionStorage.setItem('footerHeight', document.getElementById('footer').getBoundingClientRect().height.toString())
   },[])

   return (
      <div className={styles.footer} id='footer'>
         <p className={styles.footer__text}>2022</p>
      </div>
   );
}

export default Footer;