import React from 'react';
import styles from "./Messages.module.scss"

function Messages({message}) {

   return (
      <div className={styles.messages}>
         <p className={styles.messages__text}>{message}</p>
      </div>
   );
}

export default Messages;