import React from 'react';
import styles from './FieldArray.module.scss'

function FieldArray({nameField, array}) {

   return (
      <div className={styles.field}>
         <p className={styles.field__name}>{nameField} : </p>
         <ul className={styles.field__array}>
            {array.map((item, index) => (
               <li key={index}>
                  <p className={styles.field__text}>{item}{index < array.length - 1 ? `, ` : ``}</p>
               </li>
            ))}
         </ul>
      </div>
   );
}

export default FieldArray;
