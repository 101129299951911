import React, {useEffect, useState} from "react";
import styles from './Main.module.scss'
import Sidebar from "../../components/Sidebar/Sidebar";
import {Route, Routes} from "react-router-dom";
import Films from "../Films/Films";
import Home from "../Home/Home";
import CardList from "../../components/CardList/CardList";
import CardInfo from "../../components/CardInfo/CardInfo";
import {getAuth} from "firebase/auth";
import UserPage from "../UserPage/UserPage";
import AdminPanel from "../AdminPanel/AdminPanel";
import UserSettingsPage from "../UserSettingsPage/UserSettingsPage";
import UserEmailNotVerify from "../UserEmailNotVerify/UserEmailNotVerify";
import {ProtectedMainRoute} from "../../components/ProtectedRoute/ProtectedMainPage";
import FilmInfo from "../../components/FilmInfo/FilmInfo";
import AddCardPage from "../AddCardPage/AddCardPage";
import {getCardCollection} from "../../utils/cardFunction";
import {addDataCollection} from "../../store/dataCollection";
import {CONFIG_DATA_COLLECTION} from "../../utils/config";
import {useDispatch} from "react-redux";
import {getUserFromSessionStorage} from "../../utils/utility";
import AccessDenied from "../AccessDenied/AccessDenied";
import {AccessRoute} from "../../components/ProtectedRoute/AccessRoute";
import EditCardPage from "../EditCardPage/EditCardPage";

function Main({onChangeUserName}) {

   const dispatch = useDispatch();

   let collectionName = ''
   const auth = getAuth();
   const [isReady, setIsReady] = useState(false)


   const documentPath = document.location.pathname.match(/[^\/]+(?=\/)/g)

   useEffect(() => {
      setIsReady(false)
      getCardCollection('config') // Получение коллекции конфигурации
         .then((res) => {
            setIsReady(true)
            dispatch(addDataCollection({
               collection: CONFIG_DATA_COLLECTION['config'].collection,
               data: res[0],
            }))
         })

      // // Прокрутка к верху
      window.scrollTo(0, 0)
   }, [])

   if (documentPath) {
      collectionName = documentPath[0]
   }
   return (
      isReady && auth &&
      <div className={styles.home}>
         <div className={styles.home__sidebar} id='sidebar'>
            <Sidebar/>
         </div>
         <div className={styles.home__main}>
            <Routes>
               <Route path="/" element={<Home/>}/>
               <Route path="films" element={<Films/>}/>
               <Route path=":collection" element={<CardList/>}/>

               <Route path="admin" element={

                  <AccessRoute
                     isVerify={getUserFromSessionStorage().role && getUserFromSessionStorage().role === 'admin'}>
                     <AdminPanel/>
                  </AccessRoute>
               }/>

               <Route path="user-settings" element={
                  <ProtectedMainRoute isVerify={getUserFromSessionStorage().emailVerified}>
                     <UserSettingsPage/>
                  </ProtectedMainRoute>
               }/>

               <Route path="user-page" element={
                  <ProtectedMainRoute isVerify={getUserFromSessionStorage().emailVerified}>
                     <UserPage onChangeUserName={onChangeUserName}/>
                  </ProtectedMainRoute>
               }/>

               <Route path=":collection/add-card" element={
                  <ProtectedMainRoute isVerify={getUserFromSessionStorage().emailVerified}>
                     <AddCardPage/>
                  </ProtectedMainRoute>
               }/>

               <Route path=":collection/:id" element={
                  <ProtectedMainRoute isVerify={getUserFromSessionStorage().emailVerified}>
                     <CardInfo/>
                  </ProtectedMainRoute>
               }/>

               <Route path=":collection/:id/edit" element={
                  <ProtectedMainRoute isVerify={getUserFromSessionStorage().emailVerified}>
                     <EditCardPage/>
                  </ProtectedMainRoute>
               }/>


               <Route path="films/:id" element={
                  <ProtectedMainRoute isVerify={getUserFromSessionStorage().emailVerified}>
                     <FilmInfo/>
                  </ProtectedMainRoute>
               }/>

               <Route path="email-not-verified" element={<UserEmailNotVerify/>}/>

               <Route path="access-denied" element={<AccessDenied/>}/>

            </Routes>
         </div>
      </div>
   );
}

export default Main;