import {Navigate, useLocation} from 'react-router-dom';

const AccessRoute = ({children, isVerify}) => {
    const location = useLocation();

    if (!isVerify) {
        return <Navigate to='/access-denied' state={{from: location}}/>
    }

    return children;
}

export {AccessRoute};