import React, {useState} from "react";
import styles from "./UserEmailNotVerify.module.scss";
import {getAuth, sendEmailVerification, signOut} from "firebase/auth";
import Image from "../../image/avatar-default.png";
import {Popup} from "../../components/Modal/Popup/Popup";
import Messages from "../../components/Modal/Messages/Messages";
import {removeUserFromSessionStorage} from "../../utils/utility";
import {useNavigate} from "react-router-dom";

function UserEmailNotVerify() {

   const [opened, setOpened] = useState(false);
   const navigate = useNavigate();

   const verifyEmail = () => {
      setOpened(true)
   }

   const sendLinkToEmail = () => {
      const auth = getAuth();
      sendEmailVerification(auth.currentUser)
         .then(() => {
            console.log('Email verification sent')
            const auth = getAuth();
            signOut(auth)
               .then(() => {
                  removeUserFromSessionStorage()
                  navigate('/', {replace: true})
               }).catch((error) => {
            });
         })
   }

   return (
      <div className={styles.block}>
         <img src={Image} alt=""
              className={styles.block__image}/>
         <h1 className={styles.block__title}>Доступ ограничен для пользователей не подтвердивших свою электронную
            почту</h1>

         <div className={styles.buttonArea}>
            <button type="button"
                    className={styles.buttonArea__button}
                    onClick={verifyEmail}
            >
               Подтвердить адрес электронной почты
            </button>
         </div>

         <Popup isOpened={opened}
                onClose={() => setOpened(false)}
                isTransparent={false}
                isClosableOverlay={false}>
            <Messages onClickCancel={() => setOpened(false)}
                      onClickOK={sendLinkToEmail}/>
         </Popup>

      </div>

   );
}

export default UserEmailNotVerify;
