import React from 'react';
import styles from './CheckBox.module.scss'

function CheckBox({inputName, changeValue, isChecked}) {

    const handlerOnChangeCheck = (evt) => {
       const {name, checked} = evt.target;
        changeValue(name, checked)
    }

    return (
       <div className={styles.checkboxArea}>
          <input type="checkbox" className={styles.checkbox} id={inputName}
                 name={inputName}
                 defaultChecked={isChecked}
                 onChange={handlerOnChangeCheck}
          />
          <label htmlFor={inputName}></label>
       </div>
    );
}

export default CheckBox;