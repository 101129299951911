import {Routes, Route, useNavigate} from "react-router-dom";
import NotFound from "../../pages/NotFound/NotFound";
import {ProtectedRoute} from "../ProtectedRoute/ProtectedRoute";
import Main from "../../pages/Main/Main";
import Login from "../../pages/Login/Login";
import React, {useEffect, useState} from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import styles from "./App.module.scss";
import Registration from "../../pages/Registration/Registration";
import {useDispatch} from "react-redux";
import ResetPassword from "../../pages/ResetPassword/ResetPassword";
import {getAuth, signOut, onAuthStateChanged} from "firebase/auth";
import {useAuth} from "../../hooks/useAuth";
import SentResetPassword from "../../pages/SentResetPassword/SentResetPassword";
import {AuthRoute} from "../ProtectedRoute/AuthRoute";
import {getUserFromSessionStorage, removeUserFromSessionStorage, setUserToSessionStorage} from "../../utils/utility";
import {SidebarLink} from "../Sidebar/SidebarLink/SidebarLink";
import FairebaseApi from "../../utils/FairebaseApi";

function App() {

   const [opened, setOpened] = useState(false);
   const [userName, setUserName] = useState(null);
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const {signIn} = useAuth();

   const handlerOnChangeUserName = (name) => {
      setUserName(name)
   }

   // Функция выхода
   const onLogOut = () => {
      const auth = getAuth();
      signOut(auth)
         .then(() => {
            removeUserFromSessionStorage()
            navigate('/', {replace: true})
         }).catch((error) => {
      });
   }

   // Функция авторизации пользователя
   const onLogIn = (fromPage) => {
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
         if (user) {
            FairebaseApi.getDocument('dataUsers', 'email', user.email)
               .then((res) => {
                  const currentUser = {
                     email: user.email,
                     uid: user.uid,
                     emailVerified: user.emailVerified,
                     userName: user.displayName,
                     rights: res.currentDocumentData[0].service.rights || false,
                     role: res.currentDocumentData[0].service.role || false
                  }
                  signIn(currentUser, () => navigate(fromPage, {replace: true}));
                  setUserToSessionStorage(currentUser)
                  setUserName(getUserFromSessionStorage().userName)

               })
         } else {
            console.log('Пользователь не авторизован')
         }
      });
   }

   const handlerOnClickClose = () => {
      setOpened(false)
      onLogOut();
   }

   const handlerOnClickHamburger = () => {
      setOpened(!opened)
   }

   useEffect(() => {
      document.getElementById('hamburger_menu').style.top = document.getElementById('header').offsetHeight + 'px';
   }, [])

   return (
      <div className={styles.layout}>
         <Header onLogOut={onLogOut}
                 userName={userName}
                 onClickHamburger={handlerOnClickHamburger}
                 isOpenHamburgerMenu={opened}
         />
         <div className={styles.headerBlock}></div>

         <div className={styles.main}>
            <Routes>

               {/*Главная страница*/}
               <Route path="/*" element={
                  <ProtectedRoute>
                     <Main onChangeUserName={handlerOnChangeUserName}/>
                  </ProtectedRoute>
               }/>

               {/*Страница авторизации*/}
               <Route path="login" element={
                  <AuthRoute>
                     <Login onLogIn={onLogIn}/>
                  </AuthRoute>
               }/>

               {/*Страница регистрации*/}
               <Route path="registration" element={
                  <AuthRoute>
                     <Registration onLogIn={onLogIn}/>
                  </AuthRoute>
               }/>

               {/*Страница сброса пароля*/}
               <Route path="resetpassword" element={
                  <AuthRoute>
                     <ResetPassword/>
                  </AuthRoute>
               }/>

               {/*Страница уведомления направления ссылки*/}
               <Route path="sentreset" element={
                  <AuthRoute>
                     <SentResetPassword/>
                  </AuthRoute>
               }/>

               {/*Страница Не найдено*/}
               <Route path="*" element={<NotFound/>}/>
            </Routes>
         </div>
         <Footer/>

         <div
            className={opened ? `${styles.hamburgerMenu} ${styles.hamburgerMenu_active}` : `${styles.hamburgerMenu}`}
            id='hamburger_menu'
         >
            <ul className={styles.hamburgerMenu__list}>
               <li onClick={() => setOpened(false)}
                   className={styles.hamburgerMenu__link}>
                  <SidebarLink to="/">Главная</SidebarLink>
               </li>
               <li onClick={() => setOpened(false)}
                   className={styles.hamburgerMenu__link}>
                  <SidebarLink to="/films">Фильмы</SidebarLink>
               </li>
               <li onClick={() => setOpened(false)}
                   className={styles.hamburgerMenu__link}>
                  <SidebarLink to="/planets">Планеты</SidebarLink>
               </li>
               <li onClick={() => setOpened(false)}
                   className={styles.hamburgerMenu__link}>
                  <SidebarLink to="/people">Персонажи</SidebarLink>
               </li>
               <li onClick={() => setOpened(false)}
                   className={styles.hamburgerMenu__link}>
                  <SidebarLink to="/species">Расы</SidebarLink>
               </li>
               <li onClick={() => setOpened(false)}
                   className={styles.hamburgerMenu__link}>
                  <SidebarLink to="/starships">Звездолеты</SidebarLink>
               </li>
               <li onClick={() => setOpened(false)}
                   className={styles.hamburgerMenu__link}>
                  <SidebarLink to="/vehicles">Транспорт</SidebarLink>
               </li>
            </ul>
         </div>

      </div>

   );
}

export default App;

