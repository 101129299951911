export const CARD_INFO_CONFIG = {
   people: {
      avatar_directory: 'people',
      avatar_file: 'img_filename',
      content: [
         {
            field_name: 'birth_year',
            name: 'Дата рождения',
            type: 'text',
         },
         {
            field_name: 'death_year',
            name: 'Дата смерти',
            type: 'text',
         },
         {
            field_name: 'gender',
            name: 'Пол',
            type: 'text',
         },
         {
            field_name: 'eye_color',
            name: 'Цвет глаз',
            type: 'array',
         },
         {
            field_name: 'hair_color',
            name: 'Цвет волос',
            type: 'array',
         },
         {
            field_name: 'skin_color',
            name: 'Цвет кожи',
            type: 'array',
         },
         {
            field_name: 'height',
            name: 'Рост',
            postfix: 'cм.',
            type: 'text',
         },
         {
            field_name: 'mass',
            name: 'Вес',
            postfix: 'кг.',
            type: 'text',
         },
         {
            field_name: 'homeworld',
            name: 'Планета',
            path: 'planets',
            type: 'link',
         },
         {
            field_name: 'species',
            name: 'Раса',
            path: 'species',
            type: 'link',
         },
         {
            field_name: 'parents',
            name: 'Родители',
            path: 'people',
            type: 'link',
         },
         {
            field_name: 'siblings',
            name: 'Братья/сестры',
            path: 'people',
            type: 'link',
         },
         {
            field_name: 'partner',
            name: 'Партнер(ы)',
            path: 'people',
            type: 'link',
         },
         {
            field_name: 'children',
            name: 'Дети',
            path: 'people',
            type: 'link',
         },
      ],
      description: 'peopleDescription',
   },
   species: {
      avatar_directory: 'species',
      avatar_file: 'img_filename',
      content: [
         {
            field_name: 'language',
            name: 'Язык',
            type: 'text',
         },
         {
            field_name: 'average_height',
            name: 'Средний рост',
            postfix: 'м.',
            type: 'text',
         },
         {
            field_name: 'average_lifespan',
            name: 'Средняя продолжительность жизни',
            postfix: 'лет',
            type: 'text',
         },
         {
            field_name: 'classification',
            name: 'Классификация',
            type: 'text',
         },
         {
            field_name: 'designation',
            name: 'Форма жизни',
            type: 'text',
         },
         {
            field_name: 'eye_colors',
            name: 'Цвет глаз',
            type: 'array',
         },
         {
            field_name: 'hair_colors',
            name: 'Цвет волос',
            type: 'array',
         },
         {
            field_name: 'skin_colors',
            name: 'Цвет кожи',
            type: 'array',
         },
         {
            field_name: 'people',
            name: 'Персонажи',
            path: 'people',
            type: 'link',
         },
      ],
      description: 'speciesDescription',
   },
   planets: {
      avatar_directory: 'planets',
      avatar_file: 'img_filename',
      content: [
         {
            field_name: 'gravity',
            name: 'Гравитация',
            postfix: 'земных единиц',
            type: 'text',
         },
         {
            field_name: 'diameter',
            name: 'Диаметр',
            type: 'text',
            postfix: 'км.'
         },
         {
            field_name: 'orbital_period',
            name: 'Орбитальный период обращения',
            type: 'text',
            postfix: 'дней'
         },
         {
            field_name: 'rotation_period',
            name: 'Период обращения вокруг оси',
            type: 'text',
            postfix: 'час.'
         },
         {
            field_name: 'population',
            name: 'Численность населения',
            type: 'text',
            postfix: '',
         },
         {
            field_name: 'climate',
            name: 'Климат',
            type: 'text',
            postfix: '',
         },
         {
            field_name: 'surface_water',
            name: 'Площадь покрытия водой',
            type: 'text',
            postfix: '%'
         },
         {
            field_name: 'terrain',
            name: 'Ландшафт',
            type: 'array',
         },
         {
            field_name: 'people',
            name: 'Персонажи',
            path: 'people',
            type: 'link',
         },
      ],
      description: 'planetDescription',
   },
   starships: {
      avatar_directory: 'starships',
      avatar_file: 'img_filename',
      content: [
         {
            field_name: 'starship_class',
            name: 'Класс',
            type: 'text',
         },
         {
            field_name: 'model',
            name: 'Модель',
            type: 'text',
         },
         {
            field_name: 'length',
            name: 'Длина',
            type: 'text',
            postfix: 'м.'
         },
         {
            field_name: 'crew',
            name: 'Экипаж',
            type: 'text',
            postfix: 'чел.',
         },
         {
            field_name: 'passengers',
            name: 'Пассажиров',
            type: 'text',
            postfix: 'чел.',
         },
         {
            field_name: 'cargo_capacity',
            name: 'Грузоподьемность',
            type: 'text',
            postfix: 'тонн'
         },
         {
            field_name: 'cost_in_credits',
            name: 'Стоимость в кредитах',
            type: 'text',
         },
         {
            field_name: 'hyperdrive_rating',
            name: 'Гипердрайв рейтинг',
            type: 'text',
         },
         {
            field_name: 'max_atmosphering_speed',
            name: 'Максимальная скорость в атмосфере',
            type: 'text',
         },
         {
            field_name: 'manufacturer',
            name: 'Производитель',
            type: 'text',
         },

      ],
      description: 'starshipDescription',
   },
   vehicles: {
      avatar_directory: 'vehicle',
      avatar_file: 'img_filename',
      content: [
         {
            field_name: 'model',
            name: 'Модель',
            type: 'text',
         },
         {
            field_name: 'length',
            name: 'Длина',
            type: 'text',
            postfix: 'м.'
         },
         {
            field_name: 'crew',
            name: 'Количество членов экипажа',
            type: 'text',
         },
         {
            field_name: 'passengers',
            name: 'Количество пассажиров',
            type: 'text',
         },
         {
            field_name: 'cargo_capacity',
            name: 'Грузоподьемность',
            type: 'text',
            postfix: 'тонн'
         },
         {
            field_name: 'cost_in_credits',
            name: 'Стоимость в кредитах',
            type: 'text',
         },
         {
            field_name: 'max_atmosphering_speed',
            name: 'Максимальная скорость в атмосфере',
            type: 'text',
            postfix: 'км/ч.'
         },
         {
            field_name: 'manufacturer',
            name: 'Производитель',
            type: 'text',
         },
      ],
      description: 'vehicleDescription',
   },
   films: {
      avatar_directory: 'films',
      avatar_file: 'image_url_min',
      content: [
         {
            field_name: 'release_date',
            name: ' Дата выхода',
            type: 'text',
         },
         {
            field_name: 'director',
            name: 'Режиссер',
            type: 'text',
         },
         {
            field_name: 'music',
            name: 'Композитор',
            type: 'text',
         },
         {
            field_name: 'producer',
            name: 'Продюсеры',
            type: 'text',
         },
         {
            field_name: 'script',
            name: 'Сценарий',
            type: 'text',
         },
         {
            field_name: 'budget',
            name: 'Бюджет',
            type: 'text',
            postfix: '$'
         },
         {
            field_name: 'timing',
            name: 'Продолжительность',
            type: 'text',
            postfix: 'мин.',
         },
      ],
      description: 'filmDescription',
   },
}

// export const CARD_SLICE_CONFIG = {
//    people: {
//       isReady: 'isDataPeople', // В redux переменная, где true - если получены данные из базы данных, false - если нет
//       slices: ['getDataPeople', 'getDataPlanets', 'getDataSpecies'] // перечень редюсеров для получения данных из базы
//    },
//    species: {
//       isReady: 'isDataSpecies',
//       slices: ['getDataSpecies']
//    },
//    planets: {
//       isReady: 'isDataPlanets',
//       slices: ['getDataPlanets']
//    },
//    starships: {
//       isReady: 'isDataStarships',
//       slices: ['getDataStarships']
//    },
//    vehicles: {
//       isReady: 'isDataVehicles',
//       slices: ['getDataVehicles']
//    },
//    films: {
//       isReady: 'isDataFilms',
//       slices: ['getDataFilms']
//    },
//    settings: {
//       isReady: 'isDataSettings',
//       slices: ['getDataSettings']
//    },
// }

export const CONFIG_DATA_COLLECTION = {
   people: {
      collection: 'dataPeople',
      title: 'Персонажи',
      description: 'peopleDescription',
      newCardTitle: 'нового персонажа',
      avatar_directory: 'people',
   },
   species: {
      collection: 'dataSpecies',
      title: 'Расы',
      description: 'speciesDescription',
      newCardTitle: 'новой расы',
      avatar_directory: 'species',
   },
   planets: {
      collection: 'dataPlanets',
      title: 'Планеты',
      description: 'planetDescription',
      newCardTitle: 'новой планеты',
      avatar_directory: 'planets',
   },
   starships: {
      collection: 'dataStarships',
      title: 'Звездолеты',
      description: 'starshipDescription',
      newCardTitle: 'нового звездолета',
      avatar_directory: 'starships',
   },
   vehicles: {
      collection: 'dataVehicles',
      title: 'Транспорт',
      description: 'vehicleDescription',
      newCardTitle: 'нового транспорта',
      avatar_directory: 'vehicle',
   },
   settings: {
      collection: 'dataSettings',
      title: 'Настройки',
   },
   films: {
      collection: 'dataFilms',
      title: 'Фильмы',
      description: 'filmDescription',
      newCardTitle: 'нового фильма',
   },
   config: {
      collection: 'dataConfig',
   },
   temp: {
      collection: 'temp',
      description: 'tempDescription',
      avatar_directory: 'temp',
   },
}

export const CARD_CONFIG = {
   people: {
      avatar_directory: 'people',
      content: [
         {
            field_name: 'name',
            name: 'Имя',
            type: 'text',
            link: false,
         },
         {
            field_name: 'gender',
            name: 'Пол',
            type: 'text',
            link: false,
         },
         {
            field_name: 'species',
            name: 'Раса',
            link: true,
            path: 'dataSpecies'
         },
      ],
   },
   species: {
      avatar_directory: 'species',
      avatar_file: 'img_filename',
      content: [
         {
            field_name: 'name',
            name: 'Наимен.',
            link: false,
         },
         {
            field_name: 'designation',
            name: 'Форма жизни',
            link: false,
            path: '',
         },
         {
            field_name: 'classification',
            name: 'Класс',
            link: false,
            path: '',
         },
      ],
   },
   planets: {
      avatar_directory: 'planets',
      content: [
         {
            field_name: 'name',
            name: 'Наимен.',
            link: false,
         },
         {
            field_name: 'population',
            name: 'Обитателей',
            link: false,
         },
         {
            field_name: 'gravity',
            name: 'Гравитация (зем. ед.)',
            link: false,
         },
      ],
      description: 'planetDescription',
   },
   starships: {
      avatar_directory: 'starships',
      content: [
         {
            field_name: 'name',
            name: 'Наимен.',
            link: false,
         },
         {
            field_name: 'starship_class',
            name: 'Класс',
            link: false,
         },
         {
            field_name: 'model',
            name: 'Модель',
            link: false,
         },
      ],
   },
   vehicles: {
      avatar_directory: 'vehicle',
      content: [
         {
            field_name: 'name',
            name: 'Наимен.',
            link: false,
         },
         {
            field_name: 'model',
            name: 'Модель',
            link: false,
            path: '',
            postfix: 'м.'
         },
      ],
   },
}

export const USER_DATA = {
   email: '',
   avatar_url: null,
   user_auth_id: '',
   service: {
      email_confirmation: false,
      email_confirmation_code: '',
      email_confirmation_date: null,
      role: 'user',
      rights: {
         add: false,
         read: true,
         delete: false,
         write: false,
      },
      create_date: null,
   },
   user_data: {
      user_name: {
         data: 'Пришелец',
         name: 'имя',
         public: true,
      },
      age: {
         data: null,
         name: 'возраст',
         public: true,
         postfix: 'лет',
      },
      eye_color: {
         data: null,
         name: 'цвет глаз',
         public: true,
      },
      gender: {
         data: null,
         name: 'пол',
         public: true,
      },
      hair_color: {
         data: null,
         name: 'цвет волос',
         public: true,
      },
      height: {
         data: null,
         name: 'рост',
         public: true,
         postfix: 'см.',
      },
      location: {
         data: 'Ланиакея, сверхскопление Девы -> Комплекс сверхскоплений Рыбы – Кита -> Галактическая нить Персея – Пегаса -> Галактика Млечный Путь -> Рукав Ориона -> Солнечная система -> Земля - третья планета от Солнца',
         name: 'место проживания',
         public: true,
      },
      skin_color: {
         data: null,
         name: 'цвет кожи',
         public: true,
      },
      weight: {
         data: null,
         name: 'вес',
         public: true,
         postfix: 'кг.',
      },
      species: {
         data: 'Human',
         name: 'раса',
         public: true,
      },
   }
}

export const CARD_ADD_CONFIG = {
   people: {
      avatar_directory: 'people',
      content: [
         {
            field_name: 'name',
            name: 'Наименование',
            type: 'text',
         },
         {
            field_name: 'homeworld',
            name: 'Родной мир',
            sourceBase: 'planets',
            viewField: 'name',
            returnField: 'pk',
            typeSource: 'base',
            type: 'select',
            array: true,
            multiple: false,
         },
         {
            field_name: 'species',
            name: 'Раса',
            sourceBase: 'species',
            viewField: 'name',
            returnField: 'pk',
            typeSource: 'base',
            type: 'select',
            array: true,
            multiple: false,
         },
         {
            field_name: 'gender',
            name: 'Пол',
            sourceBase: 'config',
            viewField: 'gender',
            typeSource: 'classifier',
            type: 'select',
            multiple: false,
         },
         {
            field_name: 'parents',
            name: 'Родители',
            sourceBase: 'people',
            viewField: 'name',
            returnField: 'pk',
            typeSource: 'base',
            type: 'select',
            multiple: true,
            array: true,
         },
         {
            field_name: 'siblings',
            name: 'Братья/сестры',
            sourceBase: 'people',
            viewField: 'name',
            returnField: 'pk',
            typeSource: 'base',
            type: 'select',
            multiple: true,
            array: true,
         },
         {
            field_name: 'partner',
            name: 'Партнер(ы)',
            sourceBase: 'people',
            viewField: 'name',
            returnField: 'pk',
            typeSource: 'base',
            type: 'select',
            multiple: true,
            array: true,
         },
         {
            field_name: 'children',
            name: 'Дети',
            sourceBase: 'people',
            viewField: 'name',
            returnField: 'pk',
            typeSource: 'base',
            type: 'select',
            multiple: true,
            array: true,
         },
         {
            field_name: 'brief',
            name: 'Описание',
            type: 'text-area',
         },
         {
            field_name: 'birth_year',
            name: 'Дата рождения',
            type: 'text',
         },
         {
            field_name: 'death_year',
            name: 'Дата смерти',
            type: 'text',
         },
         {
            field_name: 'eye_color',
            name: 'Цвет глаз',
            type: 'text',
            array: true,
         },
         {
            field_name: 'hair_color',
            name: 'Цвет волос',
            type: 'text',
            array: true,
         },
         {
            field_name: 'skin_color',
            name: 'Цвет кожи',
            type: 'text',
            array: true,
         },
         {
            field_name: 'height',
            name: 'Рост',
            postfix: 'cм.',
            type: 'number',
         },
         {
            field_name: 'mass',
            name: 'Вес',
            postfix: 'кг.',
            type: 'number',
         },
      ],
      description: 'peopleDescription',
   },
   species: {
      avatar_directory: 'species',
      content: [
         {
            field_name: 'name',
            name: 'Наименование',
            type: 'text',
         },
         {
            field_name: 'classification',
            name: 'Классификация',
            sourceBase: 'config',
            viewField: 'classification',
            typeSource: 'classifier',
            type: 'select',
            multiple: false,
         },
         {
            field_name: 'designation',
            name: 'Разумность',
            sourceBase: 'config',
            viewField: 'designation',
            typeSource: 'classifier',
            type: 'select',
            multiple: false,
         },
         {
            field_name: 'brief',
            name: 'Описание',
            type: 'text-area',
         },
         {
            field_name: 'language',
            name: 'Язык',
            type: 'text',
         },
         {
            field_name: 'average_height',
            name: 'Средний рост',
            postfix: 'cм.',
            type: 'number',
         },
         {
            field_name: 'average_lifespan',
            name: 'Средняя продолжительность жизни',
            postfix: 'лет',
            type: 'number',
         },
         {
            field_name: 'eye_colors',
            name: 'Цвет глаз',
            type: 'text',
            array: true,
         },
         {
            field_name: 'hair_colors',
            name: 'Цвет волос',
            type: 'text',
            array: true,
         },
         {
            field_name: 'skin_colors',
            name: 'Цвет кожи',
            type: 'text',
            array: true,
         },
      ],
      description: 'speciesDescription',
   },
   planets: {
      avatar_directory: 'planets',
      avatar_file: 'img_filename',
      content: [
         {
            field_name: 'name',
            name: 'Наименование',
            type: 'text',
         },
         {
            field_name: 'brief',
            name: 'Описание',
            type: 'text-area',
         },
         {
            field_name: 'gravity',
            name: 'Гравитация',
            type: 'number',
            postfix: 'земных единиц'
         },
         {
            field_name: 'diameter',
            name: 'Диаметр',
            type: 'number',
            postfix: 'км.'
         },
         {
            field_name: 'orbital_period',
            name: 'Орбитальный период обращения',
            type: 'number',
            postfix: 'дней'
         },
         {
            field_name: 'rotation_period',
            name: 'Период обращения вокруг оси',
            type: 'number',
            postfix: 'час.'
         },
         {
            field_name: 'population',
            name: 'Численность населения',
            type: 'number',
         },
         {
            field_name: 'climate',
            name: 'Климат',
            path: 'text',
            array: true,
         },
         {
            field_name: 'surface_water',
            name: 'Площадь покрытия водой',
            type: 'number',
            postfix: '%'
         },
         {
            field_name: 'terrain',
            name: 'Ландшафт',
            type: 'text',
            postfix: '',
            array: true,
         },
      ],
      description: 'planetDescription',
   },
   starships: {
      avatar_directory: 'starships',
      avatar_file: 'img_filename',
      content: [
         {
            field_name: 'name',
            name: 'Наименование',
            type: 'text',
         },
         {
            field_name: 'starship_class',
            name: 'Класс',
            sourceBase: 'config',
            viewField: 'starshipClass',
            typeSource: 'classifier',
            multiple: false,
         },
         {
            field_name: 'brief',
            name: 'Описание',
            type: 'text-area',
         },
         {
            field_name: 'model',
            name: 'Модель',
            type: 'text',
         },
         {
            field_name: 'length',
            name: 'Длина',
            type: 'number',
            postfix: 'м.'
         },
         {
            field_name: 'crew',
            name: 'Экипаж',
            type: 'number',
            postfix: 'чел.',
         },
         {
            field_name: 'passengers',
            name: 'Пассажиров',
            type: 'number',
            postfix: 'чел.',
         },
         {
            field_name: 'cargo_capacity',
            name: 'Грузоподъемность',
            type: 'number',
            postfix: 'тонн'
         },
         {
            field_name: 'cost_in_credits',
            name: 'Стоимость в кредитах',
            type: 'number',
         },
         {
            field_name: 'hyperdrive_rating',
            name: 'Гипердрайв рейтинг',
            type: 'number',
         },
         {
            field_name: 'max_atmosphering_speed',
            name: 'Максимальная скорость в атмосфере',
            type: 'number',
            postfix: 'км/час',
         },
         {
            field_name: 'manufacturer',
            name: 'Производитель',
            type: 'text',
            array: true,
         },

      ],
      description: 'starshipDescription',
   },
   vehicles: {
      avatar_directory: 'vehicle',
      avatar_file: 'img_filename',
      content: [
         {
            field_name: 'name',
            name: 'Наименование',
            type: 'text',
         },
         {
            field_name: 'brief',
            name: 'Описание',
            type: 'text-area',
         },
         {
            field_name: 'model',
            name: 'Модель',
            type: 'text',
         },
         {
            field_name: 'length',
            name: 'Длина',
            type: 'number',
            postfix: 'м.'
         },
         {
            field_name: 'crew',
            name: 'Количество членов экипажа',
            type: 'number',
         },
         {
            field_name: 'passengers',
            name: 'Количество пассажиров',
            type: 'number',
         },
         {
            field_name: 'cargo_capacity',
            name: 'Грузоподъемность',
            type: 'number',
            postfix: 'тонн'
         },
         {
            field_name: 'cost_in_credits',
            name: 'Стоимость в кредитах',
            type: 'number',
         },
         {
            field_name: 'max_atmosphering_speed',
            name: 'Максимальная скорость в атмосфере',
            type: 'number',
            postfix: 'км/час'
         },
         {
            field_name: 'manufacturer',
            name: 'Производитель',
            type: 'text',
            array: true,
         },
      ],
      description: 'vehicleDescription',
   },
   films: {
      avatar_directory: 'films',
      avatar_file: 'image_url_min',
      content: [
         {
            field_name: 'release_date',
            name: ' Дата выхода',
            type: 'text',
            link: false,
            path: '',
            postfix: '',
         },
         {
            field_name: 'director',
            name: 'Режиссер',
            type: 'text',
            link: false,
            path: '',
            postfix: ''
         },
         {
            field_name: 'music',
            name: 'Композитор',
            type: 'text',
            link: false,
            path: '',
            postfix: '',
         },
         {
            field_name: 'producer',
            name: 'Продюсеры',
            type: 'text',
            link: false,
            path: '',
            postfix: '',
         },
         {
            field_name: 'script',
            name: 'Сценарий',
            type: 'text',
            link: false,
            path: '',
            postfix: ''
         },
         {
            field_name: 'budget',
            name: 'Бюджет',
            type: 'text',
            link: false,
            path: '',
            postfix: '$'
         },
         {
            field_name: 'timing',
            name: 'Продолжительность',
            type: 'text',
            link: false,
            path: '',
            postfix: 'мин.',
         },
      ],
      description: 'filmDescription',
   },
}
