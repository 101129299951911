import React from "react";
import styles from "./UserSettingsPage.module.scss";

function UserSettingsPage() {

   return (
      <div className={styles.userSettings}>
         <h1 className={styles.userSettings__title}>Настройки пользователя</h1>

         <div className={styles.userSettings__main}>

         </div>

      </div>

   );
}

export default UserSettingsPage;
