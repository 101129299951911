import React, {useState} from "react";
import styles from "./AddCardPage.module.scss";
import AddCardPageAvatar from "./AddCardPageAvatar/AddCardPageAvatar";
import Input from "../../components/FormField/Input/Input";
import Select from "../../components/FormField/Select/Select";
import TextArea from "../../components/FormField/TextArea/TextArea";
import {v4 as uuidv4} from 'uuid';
import {
   addCardAvatarToStorage,
   addCardToCollection,
   countScrollWidth,
   getUserFromSessionStorage
} from "../../utils/utility";
import {useNavigate, useParams} from "react-router-dom";
import {CARD_ADD_CONFIG} from "../../utils/config";
import {Popup} from "../../components/Modal/Popup/Popup";
import Messages from "../../components/Modal/Messages/Messages";

function AddCardPage() {

   const {collection} = useParams()
   const navigate = useNavigate()

   const [opened, setOpened] = useState(false);

   const [cardAvatar, setCardAvatar] = useState(null)
   const [pageState, setPageState] = useState(null)
   const [cardDescription, setCardDescription] = useState({description: []})
   const [isButtonCancelEnabled, setIsButtonCancelEnabled] = useState(true)
   const [isButtonSubmitEnabled, setIsButtonSubmitEnabled] = useState(true)

   // Обработчик открытия popup
   const handlerOnClickOpen = (e) => {
      setOpened(true)
      // Если есть полоса прокрутки
      if (document.body.offsetHeight > window.innerHeight) {
         document.body.style.paddingRight = countScrollWidth() + 'px'; // Паддинг справа у экрана равен ширине полосы прокрутки
      }
      document.body.style.overflow = "hidden"; // Запрет прокрутки экрана
   }

   // Обработчик закрытия popup
   const handlerOnClickClose = () => {
      setOpened(false)
   }

   const handlerSelectAvatarUrl = (selectedAvatar) => {
      setCardAvatar(selectedAvatar)
   }

   const handlerOnSubmit = (evt) => {
      evt.preventDefault();
      evt.target.reset()
      handlerOnClickOpen()

      if (pageState) { // если есть данные карточки
         const cardId = uuidv4() // id карточки

         // формирование данных карточки для записи в базу данных
         const addCardData = pageState // данные из стейта
         addCardData.pk = cardId
         addCardData.userId = getUserFromSessionStorage().uid // id текущего пользователя
         addCardData.createDate = new Date().toLocaleString(); // дата создания карточки = текущей дате
         addCardData.updateDate = new Date().toLocaleString(); // дата последнего редактирования карточки = текущей дате

         // формирование описания карточки
         const arrDescription = {
            description: Object.entries(cardDescription)[0][1].map((item) => item.text),
            pk: cardId,
         }

         addCardAvatarToStorage(cardAvatar, collection)
            .then((res) => {
               addCardData.avatar_url = res || ''
               addCardToCollection(addCardData, arrDescription, collection)
                  .then((res) => {
                     handlerOnClickClose()
                     navigate(`/${collection}`, {replace: false, state: {newCard: res}});
                  })
                  .catch((error) => {
                     console.log("Ошибка добавления карточки", error)
                     document.getElementById('add-card-form').reset()
                  })
            })
      }
   }

   const handlerOnCancel = (evt) => {
      evt.preventDefault();
      document.getElementById('add-card-form').reset()
      setPageState(null)
      setIsButtonSubmitEnabled(true)
      setIsButtonCancelEnabled(true)
      navigate(`/${collection}`, {replace: true, state: null});
   }

   const handlerOnChange = (name, value) => {
      setPageState(prevState => ({...prevState, [name]: value}));
   }

   const handlerOnDeleteStringDescription = (value) => {
      setCardDescription({description: value});
   }

   const handlerOnChangeDescription = (name, value) => {
      // console.log(name, value)
      setCardDescription(prevState => ({...prevState, [name]: value}));
   }

   return (
      // isReady && currentUserData &&
      <div className={styles.addCard}>
         <h1 className={styles.addCard__title}>Добавление новой карточки</h1>

         <form action="#"
               id='add-card-form'
               onSubmit={handlerOnSubmit}
               className={styles.addCard__main}
               name='addForm'
               noValidate
         >

            <div className={styles.addCard__avatarBlock}>
               <AddCardPageAvatar onSelectAvatar={handlerSelectAvatarUrl}
               />
            </div>

            <div className={styles.addCard__content}>

               {CARD_ADD_CONFIG[collection].content.map((item, index) => (
                  item.type === 'select' // проверка является ли текущее поле типом select
                     ?
                     // Если тип поля 'select'
                     <label key={index} className={styles.addCard__items}>
                        <p className={styles.addCard__itemsName}>
                           {item.name}{item.postfix ? `, ${item.postfix}` : ''}:&nbsp;</p>
                        <Select textHeadSelect={item.multiple
                           ? 'Множественный выбор - выберите из списка'
                           : 'Выберите один элемент из списка'}
                                inputName={item.field_name} // имя поля под которым будет добавляться в базу
                                saveSelectItems={handlerOnChange} // запись выбора для дальнейшего сохранения
                                nameBaseSelect={item.sourceBase} // имя коллекции базы данных из которого будет выбор в селекте
                                viewField={item.viewField} // поле отображаемое в списке выбора
                                typeSelector={item.typeSource} // выбор из базы данных или из классификатора
                                multiple={item.multiple} // будет ли множественный выбор
                                array={item.array || false} // будет ли запись в базу массивом или нет
                        />
                     </label>
                     : item.type === 'text-area' // проверка является ли текущее поле типом text-area
                        ?
                        // Если тип поля 'text-area'
                        <label key={index}
                               className={styles.addCard__items}>
                           <p className={styles.addCard__itemsName}>
                              {item.name}{item.postfix ? `, ${item.postfix}` : ''}:&nbsp;</p>
                           <TextArea changeValue={handlerOnChange}
                                     inputName={item.field_name}
                                     inputRow={1}
                                     isArray={false}
                           />
                        </label>
                        :
                        // все остальное
                        <label key={index}
                               className={styles.addCard__items}>
                           <p className={styles.addCard__itemsName}>
                              {item.name}{item.postfix ? `, ${item.postfix}` : ''}:&nbsp;</p>
                           <Input changeValue={handlerOnChange}
                                  inputName={item.field_name}
                                  inputType={item.type}
                                  isArray={item.array}
                           />
                        </label>
               ))}

            </div>

            <div className={styles.addCard__description}>
               <p className={styles.addCard__itemsName}>Текстовая часть</p>
               <TextArea changeValue={handlerOnChangeDescription}
                         deleteString={handlerOnDeleteStringDescription}
                         inputName={'description'}
                         inputRow={1}
                         isArray={true}
               />
            </div>

            <div className={styles.addCard__buttonArea}>
               <button type="submit"
                       aria-label="submit"
                       className={`${styles.addCard__button} ${styles.addCard__button_save}`}
                       disabled={!isButtonSubmitEnabled}
                       name="form_submit">
                  Сохранить
               </button>

               <button type="button"
                       className={`${styles.addCard__button} ${styles.addCard__button_cancel}`}
                       disabled={!isButtonCancelEnabled}
                       onClick={handlerOnCancel}
               >
                  Отменить
               </button>

            </div>

         </form>

         <Popup isOpened={opened}
                isTransparent={false}
                isClosableOverlay={false}>
            <Messages message={'Идет запись . . .'}
            />
         </Popup>

      </div>

   );
}

export default AddCardPage;
