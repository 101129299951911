import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import styles from './Registration.module.scss'
import Front1 from '../../image/front_1.png'
import Front2 from '../../image/front_2.png'
import {
   getAuth,
   createUserWithEmailAndPassword,
   signInWithEmailAndPassword
} from "firebase/auth";
import {isDesktop} from "react-device-detect";

function Registration({onLogIn}) {

   const navigate = useNavigate();
   const location = useLocation();
   const fromPage = location.state?.from?.pathname || '/';

   const [currentError, setCurrentError] = useState("");
   const [loginState, setLoginState] = useState(
      {
         email: '',
         password: '',
      }
   )
   const [isValid, setIsValid] = useState(false);
   const [errorMessageEmail, setErrorMessageEmail] = useState("")
   const [errorMessagePassword, setErrorMessagePassword] = useState("")

   // Обработчик изменений в поле ввода
   const handleChange = (e) => {
      const {name, value} = e.target;
      setLoginState(prevState => ({...prevState, [name]: value}));
   }

   // Обработчик кнопки submit формы
   const handleSubmit = (e) => {
      e.preventDefault();
      const auth = getAuth();

      createUserWithEmailAndPassword(auth, loginState.email, loginState.password)
         .then((userCredential) => {
            const user = userCredential.user;
            signInWithEmailAndPassword(auth, loginState.email, loginState.password)
                .then(({user}) => {
                   onLogIn(fromPage)
                   document.body.style.overflow = "auto"; // Разрешение прокрутки экрана
                })
                .catch((error) => {
                   if (error.message === 'Firebase: Error (auth/user-not-found).' || error.message === 'Firebase: Error (auth/wrong-password).') {
                      setCurrentError('Неверный e-mail или пароль. Попробуйте еще раз.')
                   }
                })
         })
         .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
         });
   }

   // При каждом изменении в поле ввода email или password - проверка на корректность ввода
   useEffect(() => {
      const emailValidity = loginState.email.match(/^[\w-\.\d*\.]+@[\w\d]+(\.\w{2,4})$/);
      const passwordValidity = loginState.password.match(/(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/g);

      if (!(loginState.email === '' && loginState.password === '')) {
         emailValidity ? setErrorMessageEmail("") : setErrorMessageEmail("Поле должно содержать e-mail")
         passwordValidity ? setErrorMessagePassword("") : setErrorMessagePassword("Пароль должен быть длиной не менее 6 символов и содержать спецсимвол, цифру, латинскую букву в верхнем и нижнем регистре")
         setIsValid(emailValidity && passwordValidity);
      }

   }, [loginState.email, loginState.password])

   // При установке фокуса на поле ввода снятие readOnly
   // Первоначально readOnly={true}. Реализовано для того, чтобы не было автозаполнения полей ввода
   const handlerOnFocus = (e) => {
      if (isDesktop) {
         e.target.readOnly = false
      }
   }

   return (
      <div className={styles.login}>
         <div className={styles.login__block}>
            <form action="#"
                  onSubmit={handleSubmit}
                  className={styles.login__form}
                  name='register' noValidate
                  autoComplete="off">
               <h2 className={styles.login__title}>Хочешь в нашу команду?</h2>
               <p className={styles.login__name}>E-mail</p>
               <input type="text"
                      readOnly={isDesktop}
                      onFocus={handlerOnFocus}
                      value={loginState.email}
                      onChange={handleChange}
                      className={styles.login__input}
                      name="email"
                      placeholder="введите е-mail"
                      minLength="2"
                      maxLength="40" required/>
               <p className={styles.login__inputError}>{errorMessageEmail}</p>
               <p className={styles.login__name}>Пароль</p>
               <input type="password"
                      onFocus={handlerOnFocus}
                      value={loginState.password}
                      onChange={handleChange}
                      className={styles.login__input}
                      name="password"
                      placeholder="введите пароль"
                      minLength="6"
                      maxLength="20" required/>
               <p className={styles.login__inputError}>{errorMessagePassword}</p>
               <p className={styles.login__error}>{currentError}</p>
               <button type="submit"
                       aria-label="submit"
                       className={isValid ? `${styles.login__submit}`
                          : `${styles.login__submit} ${styles.login__submit_disabled}`}
                       disabled={!isValid}
                       name="form_submit">
                  Зарегистрироваться
               </button>
               <p className={styles.login__name}>Уже зарегистрированы?&nbsp;
                  <Link className={styles.login__link} to='/login'>Войти</Link>
               </p>
            </form>
         </div>

         <div className={styles.login__image}>
            <img src={Front1} alt="" className={styles.login__topImage}/>
            <img src={Front2} alt="" className={styles.login__bottomImage}/>
         </div>

      </div>

   );
}

export default Registration;