import React, {useState} from "react";
import styles from "./Header.module.scss";
import Logo from "../../image/logo-light.svg";
import User from '../../image/user-circle-light.svg'
import Hamburger from "../../image/Hamburger.svg";
import {Popup} from "../Modal/Popup/Popup";
import {countScrollWidth} from "../../utils/utility";
import MenuUser from "../Modal/MenuUser/MenuUser";

function Header({onLogOut, userName, onClickHamburger, isOpenHamburgerMenu, onChangeUserName}) {

   const [opened, setOpened] = useState(false);

   // Обработчик закрытия popup
   const handlerOnClickClose = () => {
      setOpened(false)
      onLogOut();
   }

   // Обработчик открытия popup
   const handlerOnClickOpen = (e) => {
      setOpened(true)
      // Если есть полоса прокрутки
      if (document.body.offsetHeight > window.innerHeight) {
         document.body.style.paddingRight = countScrollWidth() + 'px'; // Паддинг справа у экрана равен ширине полосы прокрутки
      }
      document.body.style.overflow = "hidden"; // Запрет прокрутки экрана
   }

   return (
      // currentUser &&
      <div className={styles.header} id='header'>
         <img src={Hamburger} alt=""
              className={sessionStorage.getItem('currentUser') ? `${styles.header__hamburger}` : `${styles.header__hamburger_hide}`}
              onClick={onClickHamburger}
         />

         <img src={Logo} alt="" className={styles.header__logo}/>
         <div
            className={sessionStorage.getItem('currentUser')
               ? `${styles.header__userBlock}`
               : `${styles.header__userBlock_nonactive}`}>
            <p className={styles.header__userText}>{userName}</p>
            <div className={!isOpenHamburgerMenu
               ? `${styles.header__userPicBlock}`
               : `${styles.header__userPicBlock} ${styles.header__userPicBlock_notClick}`}
                 onClick={handlerOnClickOpen}>
               <img src={User} alt="" className={styles.header__userPic}/>
               <div className={styles.header__triangle}></div>
            </div>

            <Popup isOpened={opened}
                   onClose={() => setOpened(false)}
                   isTransparent={true}
                   isClosableOverlay={true}>
               <MenuUser onClose={handlerOnClickClose}
                         onClosePopup={() => setOpened(false)}
                         onChangeUserName={onChangeUserName}
               />
            </Popup>
         </div>
      </div>
   );
}

export default Header;
