//В компоненту передается массив объектов для отображения "хлебных крошек" в виде
//crumbs = [
//   {
//      menu: 'Главная', - наименование
//      path: 'people' - путь для перехода (если пусто то переход на '/')
//   },
//]
import React from 'react';
import {useNavigate} from "react-router-dom";
import styles from './BreadCrumbs.module.scss'

function BreadCrumbs({crumbs}) {
   const navigate = useNavigate();

   // Создание ссылки для перехода по клику в строке хлебных крошек
   const goCollection = (collection) => navigate('/' + collection, {replace: true});
   const crumbsLength = crumbs.length

   return (
      <div className={styles.bread}>
         {crumbs.map((item, index) => (
            <span key={index}>
               <span className={index < crumbsLength - 1 ? `${styles.bread__menu}` : ``}
                     onClick={() => goCollection(item.path)}>{item.menu}</span>
               <span className={index < crumbsLength - 1 ? `` : `${styles.bread__marker}`}> > </span>
            </span>
         ))}
      </div>
   );
}

export default BreadCrumbs;