import React from "react";
import styles from "./AdminPanel.module.scss";
import {useNavigate} from "react-router-dom";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import {useDispatch} from "react-redux";
import fairebaseApi from "../../utils/FairebaseApi";
import dataUtility from "../../utils/DataUtility";
import FairebaseApi from "../../utils/FairebaseApi";
import {sortArrayObjectsByField} from "../../utils/utility";

function AdminPanel() {

   const dispatch = useDispatch();
   const navigate = useNavigate();
   const crumbs = [
      {
         menu: 'Панель администратора',
         path: '',
      },
   ]

   const saveJson = function () {

      fairebaseApi.getCollection('dataFilms')
         .then((res) => dataUtility.saveObjToJson(res, "dataFilms"))

      fairebaseApi.getCollection('dataConfig')
         .then((res) => dataUtility.saveObjToJson(res, "dataConfig"))

      fairebaseApi.getCollection('dataSettings')
         .then((res) => dataUtility.saveObjToJson(res, "dataSettings"))

      fairebaseApi.getCollection('dataPlanets')
         .then((res) => dataUtility.saveObjToJson(res, "dataPlanets"))

      fairebaseApi.getCollection('dataSpecies')
         .then((res) => dataUtility.saveObjToJson(res, "dataSpecies"))

      fairebaseApi.getCollection('dataPeople')
         .then((res) => dataUtility.saveObjToJson(res, "dataPeople"))

      fairebaseApi.getCollection('dataStarships')
         .then((res) => dataUtility.saveObjToJson(res, "dataStarships"))

      fairebaseApi.getCollection('dataVehicles')
         .then((res) => dataUtility.saveObjToJson(res, "dataVehicles"))

      fairebaseApi.getCollection('dataUsers')
         .then((res) => dataUtility.saveObjToJson(res, "dataUsers"))

      fairebaseApi.getCollection('peopleDescription')
         .then((res) => dataUtility.saveObjToJson(res, "peopleDescription"))

      fairebaseApi.getCollection('speciesDescription')
         .then((res) => dataUtility.saveObjToJson(res, "speciesDescription"))

      fairebaseApi.getCollection('planetDescription')
         .then((res) => dataUtility.saveObjToJson(res, "planetDescription"))

      fairebaseApi.getCollection('starshipDescription')
         .then((res) => dataUtility.saveObjToJson(res, "starshipDescription"))

      fairebaseApi.getCollection('vehicleDescription')
         .then((res) => dataUtility.saveObjToJson(res, "vehicleDescription"))

      fairebaseApi.getCollection('filmDescription')
         .then((res) => dataUtility.saveObjToJson(res, "filmDescription"))
   }

   const updateLinks = (collection, dataLinks, fieldName) => {
      fairebaseApi.getCollection(collection)
         .then((res) => {
            res.forEach((item) => {
               if (dataLinks[item.pk]) {
                  const sortArray = sortArrayObjectsByField(dataLinks[item.pk], 'name')
                  FairebaseApi.updateDocToCollection(collection, {[fieldName]: sortArray}, item.id)
                     .then((res) => {
                        console.log('Данные успешно обновлены')
                     })
                     .catch((err) => {
                        console.log('Ошибка обновления данных', err)
                     })
               }
            })
         })
   }

   const linksToUpdate = () => {
      const speciesLinks = {
         people: {},
      }
      const planetLinks = {
         people: {},
         species: {},
      }

      fairebaseApi.getCollection('dataPeople')
         .then((res) => {
            res.forEach((item) => {
               if (item.species && item.species[0]) {
                  if (!speciesLinks.people[item.species[0].pk]) {
                     speciesLinks.people[item.species[0].pk] = []
                     speciesLinks.people[item.species[0].pk].push({name: item.name, pk: item.pk})
                  } else {
                     speciesLinks.people[item.species[0].pk].push({name: item.name, pk: item.pk})
                  }

               }
               if (item.homeworld && item.homeworld[0]) {
                  if (!planetLinks.people[item.homeworld[0].pk]) {
                     planetLinks.people[item.homeworld[0].pk] = []
                     planetLinks.people[item.homeworld[0].pk].push({name: item.name, pk: item.pk})

                  } else {
                     planetLinks.people[item.homeworld[0].pk].push({name: item.name, pk: item.pk})
                  }
               }

            })
            updateLinks('dataSpecies', speciesLinks.people, 'people')
            updateLinks('dataPlanets', planetLinks.people, 'people')
         })

      fairebaseApi.getCollection('dataSpecies')
         .then((res) => {
            res.forEach((item) => {

               if (item.homeworld && item.homeworld[0]) {
                  if (!planetLinks.species[item.homeworld[0].pk]) {
                     planetLinks.species[item.homeworld[0].pk] = []
                     planetLinks.species[item.homeworld[0].pk].push({name: item.name, pk: item.pk})
                  } else {
                     planetLinks.species[item.homeworld[0].pk].push({name: item.name, pk: item.pk})
                  }
               }
            })
            updateLinks('dataPlanets', planetLinks.species, 'species')
         })
   }

   // Прокрутка к верху
   window.scrollTo(0, 0)

   return (
      <div className={styles.settings}>
         <BreadCrumbs crumbs={crumbs}/>

         <div className={styles.settings__main}>
            <br/><br/>
            <button onClick={saveJson} className={styles.home__button}>Сохранить базу</button>
            <br/><br/>
            <button onClick={linksToUpdate} className={styles.home__button}>Обновить ссылки</button>
            <br/><br/>

         </div>

      </div>
   );
}

export default AdminPanel;
