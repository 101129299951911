import {CARD_INFO_CONFIG} from './config'
import fairebaseApi from "./FairebaseApi";
import FairebaseApi from "./FairebaseApi";

class DataUtility {
   constructor() {
   }

   // Сохранение объекта как JSON
   saveObjToJson = (obj, name) => {
      let blob = new Blob([JSON.stringify(obj)], {
         type: 'application/json'
      });
      let link = document.createElement('a');
      link.download = name + '.json';
      link.href = URL.createObjectURL(blob);
      link.click()
   }

   // Получение информации карточки (со ссылками)
   getCardInfo = (collection, id) => {

      // Получения файла конфигурации
      const config_data = CARD_INFO_CONFIG[collection]

      let view_data = {
         card_name: "",
         avatar: '',
         content: [],
      };

      // Получение документа из коллекции
      // collection - имя коллекции
      // id - идентификатор документа (pk)
      return fairebaseApi.getDocument(collection, "pk", id)
         .then((res) => {
            config_data.content.forEach((item) => {
               if (item.link) {
                  console.log(fairebaseApi.getArrayLinks(item.path, res[0][item.field_name]))
                  view_data.content.push({
                     name: item.name,
                     data: fairebaseApi.getArrayLinks(item.path, res[0][item.field_name]),
                     link: item.link,
                     path: item.path,
                  })
               } else {
                  view_data.content.push({
                     name: item.name,
                     data: res[0][item.field_name],
                     link: item.link,
                     path: item.path,
                  })
               }

            });
            view_data.card_name = res[0][config_data.card_name];


            // Получение ссылки на файл изображения
            return fairebaseApi.getUrlFile(config_data.avatar_directory, res[0][config_data.avatar_file])
               .then(res => {
                  view_data.avatar = res;
                  return view_data;
               })
               .catch((error) => {
                  console.log("Ошибка получения изображения", error.code)
                  return view_data;
               })
         })
         .catch((error) => {
            console.log("Ошибка получения данных", error.code)
         })
   }

   // getCardDescription = (collection, id) => {
   //    // Получения файла конфигурации
   //    const config_data = CARD_INFO_CONFIG[collection]
   //
   //    // Получение текста описания
   //    // collection - имя коллекции
   //    // id - идентификатор документа (pk)
   //    return fairebaseApi.getDocument(config_data.description, "pk", id)
   //       .then((res) => {
   //          return res[0].description;
   //       })
   //       .catch((error) => {
   //          console.log("Ошибка загрузки описания", error.code)
   //       })
   // }

   // getCardLinks = (collection, data) => {
   //    // Получение текста описания
   //    // collection - имя коллекции
   //    // id - идентификатор документа (pk)
   //    return fairebaseApi.getArrayLinks(collection, data)
   //       .then((res) => {
   //          console.log(res)
   //          return res;
   //       })
   //       .catch((error) => {
   //          console.log("Ошибка ссылок", error.code)
   //       })
   // }

   // getCardUrl = (collection, file) => {
   //    console.log(collection, file)
   //    return FairebaseApi.getUrlFile(collection, file)
   //       .then(res => {
   //          // console.log(res)
   //          return res
   //       })
   // }
   // getInfo = (collection, data) => {
   //
   //    // Получения файла конфигурации
   //    const config_data = CARD_INFO_CONFIG[collection]
   //    // console.log(config_data)
   //    let view_data = [];
   //
   //    // Получение документа из коллекции
   //    // collection - имя коллекции
   //    // id - идентификатор документа (pk)
   //
   //    config_data.content.forEach((item) => {
   //       view_data.push({
   //          name: item.name,
   //          data: data[item.field_name],
   //       })
   //
   //    });
   //
   //    return view_data
   // }

}

const dataUtility = new DataUtility();
export default dataUtility;