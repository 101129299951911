import React from 'react';
import styles from './InfoDescription.module.scss'

function InfoDescription({content}) {

   return (
      <ul className={styles.cardInfoDescription}>
         {content && content.map((item, index) => (
            <li className={styles.cardInfoDescription__list} key={index}>
               <p className={item.length < 50 ?
                  `${styles.cardInfoDescription__title}` :
                  `${styles.cardInfoDescription__text}`}
               >{item}</p>
            </li>
         ))}
      </ul>
   );
}

export default InfoDescription;