import React from 'react';
import styles from "./MenuUser.module.scss"
import {countScrollWidth, getUserFromSessionStorage} from "../../../utils/utility";
import {useNavigate} from "react-router-dom";

function MenuUser({onClose, onClosePopup, onChangeUserName}) {

   const navigate = useNavigate();

   // Проверка на наличие элемента DOM
   if (document.getElementById('menu')) {
      // Проверка на наличие скролла
      if (document.body.offsetHeight > window.innerHeight) {
         // Если есть - добавляем к смещению справа ширину скролла
         document.getElementById('menu').style.right = countScrollWidth() + 70 + 'px'
      } else {
         document.getElementById('menu').style.right = '70px'
      }
   }

   const handlerOnClickMenu = (path) => {
      navigate(path, {replace: true});
      document.body.style.overflow = "auto"; // Разрешение прокрутки экрана
      onClosePopup();
   }

   return (
      <div className={styles.menu} id='menu'>
         <p className={styles.menu__text}>Авторизован как</p>
         <p className={`${styles.menu__text} ${styles.menu__text_bold}`}>
            {sessionStorage.getItem('currentUser')
               ? JSON.parse(sessionStorage.getItem('currentUser')).email
               : ''}
         </p>
         <hr className={styles.menu__line}/>
         <p className={`${styles.menu__text} ${styles.menu__text_pointer}`}
            onClick={() => handlerOnClickMenu('user-page')}>Страница пользователя</p>
          <p className={`${styles.menu__text} ${styles.menu__text_pointer}`}
             onClick={() => handlerOnClickMenu('admin')}>Админ панель</p>
         <hr className={styles.menu__line}/>
         <p className={`${styles.menu__text} ${styles.menu__text_pointer}`}
            onClick={() => onClose()}>Выход</p>
      </div>
   );
}

export default MenuUser;