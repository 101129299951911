import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {addAuth} from "../store/dataUsers";

export function useAuth() {

   const dispatch = useDispatch();

   const auth = true // установка в сторе переменной, что пользователь прошел авторизацию

   // запись в стор данных о текущем аторизованном пользователе
   const signIn = (user, cb) => {
      dispatch(addAuth({auth, user}));
      cb();
   }

   const currentUser = useSelector(state => state.user)

   return {signIn}

}
