import {useLocation, Navigate} from 'react-router-dom';

const AuthRoute = ({children}) => {
   const location = useLocation();

   if (sessionStorage.getItem('currentUser')) {
         return <Navigate to='/' state={{from: location}}/>
   }
   return children;
}

export {AuthRoute};