import React, {useEffect, useState} from 'react';
import styles from "./CardInfo.module.scss";
import {useNavigate, useParams} from "react-router-dom";
import DefaultAvatar from "../../image/avatar-default.png";
import InfoDescription from "../CardFields/InfoDescription/InfoDescription";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import {
   getCardDescription,
   getCrumbsCardInfo,
   getDocumentFromCollection
} from "../../utils/cardFunction";
import {CARD_INFO_CONFIG} from "../../utils/config";
import FieldText from "../CardFields/FieldText/FieldText";
import FieldArray from "../CardFields/FieldArray/FieldArray";
import FieldLinks from "../CardFields/FieldLinks/FieldLinks";
import Pencil from '../../image/pencil.svg'
import {getUserFromSessionStorage} from "../../utils/utility";

function CardInfo() {

   const navigate = useNavigate()

   const {collection, id} = useParams();
   const [currentCard, setCurrentCard] = useState(null)
   const [crumbs, setCrumbs] = useState(null)
   const [cardDescription, setCardDescription] = useState(null)
   const [isReady, setIsReady] = useState(false)


   const handlerOnClickButtonEdit = () => {
      navigate(`/${collection}/${id}/edit`, {replace: false})
   }

   useEffect(() => {
      setIsReady(false)
      getDocumentFromCollection(collection, 'pk', id) // Получение карточки из коллекции
         .then((res) => {
            setCrumbs(getCrumbsCardInfo(collection, res.cardData.name))
            setCurrentCard(res.cardData)
            getCardDescription(collection, 'pk', id)
               .then((res) => {
                  if (res.cardData.description) {
                     setCardDescription(res.cardData.description)
                  }
                  setIsReady(true)
               })
               .finally(res => {
                  setIsReady(true)
               })
         })
      window.scrollTo(0, 0)
   }, [collection, id])

   return (
      isReady && currentCard &&
      <div className={styles.personInfo}>
         <div className={styles.personInfo_crambsBlock}>
            <BreadCrumbs crumbs={crumbs}/>
            <img className={getUserFromSessionStorage().rights && getUserFromSessionStorage().rights.write
               // Если есть право на внесение изменений кнопка видна, иначе нет
               ? `${styles.personInfo__imgButton}`
               : `${styles.personInfo__imgButton_none}`}
                 src={Pencil}
                 alt=""
                 onClick={handlerOnClickButtonEdit}
                 title={'Редактирование карточки'}
            />
         </div>
         <div className={styles.personInfo__mainInfo}>
            <img src={currentCard.avatar_url ? currentCard.avatar_url : DefaultAvatar} alt=""
                 className={styles.personInfo__image}/>

            <ul className={styles.personInfo__content}>
               {
                  CARD_INFO_CONFIG[collection].content.map((item, index) =>
                     currentCard[item.field_name] &&
                     <li key={index} className={styles.personInfo_arrayItem}>
                        {
                           item.type === 'text' && currentCard[item.field_name] && currentCard[item.field_name] !== ''
                              ?
                              <FieldText
                                 nameField={item.name}
                                 text={currentCard[item.field_name]}
                                 posfix={item.postfix || ''}
                              />
                              :
                              item.type === 'array' && currentCard[item.field_name] && currentCard[item.field_name] !== [] && typeof currentCard[item.field_name] === 'object'
                                 ?
                                 <FieldArray
                                    nameField={item.name}
                                    array={currentCard[item.field_name]}
                                 />
                                 :
                                 item.type === 'link' && currentCard[item.field_name] && typeof currentCard[item.field_name] === 'object' &&
                                 <FieldLinks
                                    nameLinks={item.name}
                                    pathLinks={item.path}
                                    dataLinks={currentCard[item.field_name]}
                                 />
                        }
                     </li>
                  )
               }
            </ul>

         </div>

         <div className={styles.personInfo__description}>
            <InfoDescription content={cardDescription}/>
         </div>

      </div>
   )
      ;

}

export default CardInfo;