import React, {useEffect, useState} from "react";
import styles from "./EditCardPage.module.scss";
import EditCardPageAvatar from "./EditCardPageAvatar/EditCardPageAvatar";
import Input from "../../components/FormField/Input/Input";
import Select from "../../components/FormField/Select/Select";
import TextArea from "../../components/FormField/TextArea/TextArea";
import {
   addCardAvatarToStorage,
   addDescriptionToCollection, countScrollWidth,
} from "../../utils/utility";
import {useNavigate, useParams} from "react-router-dom";
import {CARD_ADD_CONFIG, CONFIG_DATA_COLLECTION} from "../../utils/config";
import FairebaseApi from "../../utils/FairebaseApi";
import {Popup} from "../../components/Modal/Popup/Popup";
import MenuUser from "../../components/Modal/MenuUser/MenuUser";
import Messages from "../../components/Modal/Messages/Messages";

function EditCardPage() {

   const {collection, id} = useParams()

   const navigate = useNavigate()

   const [opened, setOpened] = useState(false);

   const [cardAvatar, setCardAvatar] = useState(null)
   const [pageState, setPageState] = useState(null)
   const [currentCard, setCurrentCard] = useState([])
   const [currentCardDescription, setCurrentCardDescription] = useState([])
   const [currentCardId, setCurrentCardId] = useState({
      cardId: null,
      descriptionId: null
   })

   const [isReady, setIsReady] = useState(false)
   const [cardDescription, setCardDescription] = useState(null)
   const [isButtonSubmitEnabled, setIsButtonSubmitEnabled] = useState(true)
   const [isButtonCancelEnabled, setIsButtonCancelEnabled] = useState(true)

   // Обработчик открытия popup
   const handlerOnClickOpen = (e) => {
      setOpened(true)
      // Если есть полоса прокрутки
      if (document.body.offsetHeight > window.innerHeight) {
         document.body.style.paddingRight = countScrollWidth() + 'px'; // Паддинг справа у экрана равен ширине полосы прокрутки
      }
      document.body.style.overflow = "hidden"; // Запрет прокрутки экрана
   }

   // Обработчик закрытия popup
   const handlerOnClickClose = () => {
      setOpened(false)
   }

   const handlerSelectAvatarUrl = (selectedAvatar) => {
      setCardAvatar(selectedAvatar)
   }

   const updateDescriptionAndAvatar = () => {
      if (cardDescription.description) {
         const arrDescription = {
            description: Object.entries(cardDescription)[0][1].map((item) => item.text),
            pk: id,
         }

         if (currentCardId.descriptionId) { // Если в базе есть описание, то изменение
            FairebaseApi.updateDocToCollection(CONFIG_DATA_COLLECTION[collection].description, arrDescription, currentCardId.descriptionId)
               .then((res) => {
                  console.log('Описание карточки обновлено')
                  handlerOnClickClose()
                  navigate(`/${collection}/${id}`, {replace: false})
               })
               .catch((err) => {
                  console.log('Ошибка обновления описания карточки', err)
                  navigate(`/${collection}/${id}`, {replace: false})
               })
         } else { // Если нет описания, то добавление
            addDescriptionToCollection(CONFIG_DATA_COLLECTION[collection].description, arrDescription)
               .then((res) => {
                  handlerOnClickClose()
                  navigate(`/${collection}/${id}`, {replace: false})
               })
               .catch((error) => {
                  console.log("Ошибка добавления описания карточки", error)
               })
         }
      }
   }

   const updateCard = () => {
      if (pageState) { // если есть данные карточки
         FairebaseApi.updateDocToCollection(CONFIG_DATA_COLLECTION[collection].collection, pageState, currentCardId.cardId)
            .then((res) => {
               console.log('Данные карточки успешно обновлены')
               updateDescriptionAndAvatar()
               setPageState(null)
            })
            .catch((err) => {
               console.log('Ошибка обновления данных карточки', err)
            })
      } else {
         updateDescriptionAndAvatar()
      }
   }


   const handlerOnSubmit = (evt) => {
      evt.preventDefault();
      evt.target.reset()
      handlerOnClickOpen()

      if (!cardAvatar) {
         updateCard()
      } else {
         addCardAvatarToStorage(cardAvatar, collection)
            .then((res) => {
               FairebaseApi.updateDocToCollection(CONFIG_DATA_COLLECTION[collection].collection, {avatar_url: res}, currentCardId.cardId)
                  .then((res) => {
                     updateCard()
                  })
            })
      }
   }

   const handlerOnCancel = (evt) => {
      evt.preventDefault();
      document.getElementById('edit-card-form').reset()
      setPageState(null)
      setIsButtonSubmitEnabled(true)
      setIsButtonCancelEnabled(true)
      navigate(`/${collection}/${id}`, {replace: true, state: null});
   }

   const handlerOnChange = (name, value) => {
      // console.log('handlerOnSubmit',name, value )

      setPageState(prevState => ({...prevState, [name]: value}));
   }

   const handlerOnDeleteStringDescription = (value) => {
      setCardDescription({description: value});
   }

   const handlerOnChangeDescription = (name, value) => {
      setCardDescription(prevState => ({...prevState, [name]: value}));
   }

   useEffect(() => {
      FairebaseApi.getDocument(CONFIG_DATA_COLLECTION[collection].collection, 'pk', id)
         .then((res) => {
            setCurrentCardId(prevState => ({...prevState, cardId: res.currentDocumentId}));
            setCurrentCard(res.currentDocumentData[0])
            FairebaseApi.getDocument(CONFIG_DATA_COLLECTION[collection].description, 'pk', id)
               .then((res) => {
                  setCurrentCardId(prevState => ({...prevState, descriptionId: res.currentDocumentId}));
                  if (res.currentDocumentData[0].description && res.currentDocumentData[0].description.length > 0) {
                     setCurrentCardDescription(res.currentDocumentData[0].description)
                     setCardDescription({
                        description: res.currentDocumentData[0].description.map(item => {
                           return {text: item, row: 1}
                        })
                     })
                     setIsReady(true)
                  } else {
                     setCurrentCardDescription([''])
                     setCardDescription({description: [{text: '', row: 1}]})
                     setIsReady(true)
                  }
               })
               .catch((err) => console.log('Ошибка получения описания', err))
            setCurrentCardDescription([''])
            setIsReady(true)
         })
   }, []);

   return (
      isReady && currentCard && cardDescription &&
      <div className={styles.addCard}>
         <h1 className={styles.addCard__title}>Редактирование карточки</h1>

         <form action="#"
               id='edit-card-form'
               onSubmit={handlerOnSubmit}
               className={styles.addCard__main}
               name='editForm'
               noValidate
         >

            <div className={styles.addCard__avatarBlock}>
               <EditCardPageAvatar onSelectAvatar={handlerSelectAvatarUrl}
                                   currentAvatar={currentCard.avatar_url}
               />
            </div>

            <div className={styles.addCard__content}>

               {CARD_ADD_CONFIG[collection].content.map((item, index) => (
                  item.type === 'select' // проверка является ли текущее поле типом select
                     ?
                     // Если тип поля 'select'
                     <label key={index} className={styles.addCard__items}>
                        <p className={styles.addCard__itemsName}>
                           {item.name}{item.postfix ? `, ${item.postfix}` : ''}:&nbsp;</p>
                        <Select textHeadSelect={item.multiple
                           ? 'Множественный выбор - выберите из списка'
                           : 'Выберите один элемент из списка'}
                                inputName={item.field_name} // имя поля под которым будет добавляться в базу
                                saveSelectItems={handlerOnChange} // запись выбора для дальнейшего сохранения
                                nameBaseSelect={item.sourceBase} // имя коллекции базы данных из которого будет выбор в селекте
                                viewField={item.viewField} // поле отображаемое в списке выбора
                                typeSelector={item.typeSource} // выбор из базы данных или из классификатора
                                multiple={item.multiple} // будет ли множественный выбор
                                array={item.array || false} // будет ли запись в базу массивом или нет
                                defaultValue={currentCard[item.field_name]}
                        />
                     </label>
                     : item.type === 'text-area' // проверка является ли текущее поле типом text-area
                        ?
                        // Если тип поля 'text-area'
                        <label key={index}
                               className={styles.addCard__items}>
                           <p className={styles.addCard__itemsName}>
                              {item.name}{item.postfix ? `, ${item.postfix}` : ''}:&nbsp;</p>
                           <TextArea changeValue={handlerOnChange}
                                     inputName={item.field_name}
                                     inputRow={1}
                                     isArray={false}
                                     inputValue={currentCard[item.field_name]}
                           />
                        </label>
                        :
                        // все остальное
                        <label key={index}
                               className={styles.addCard__items}>
                           <p className={styles.addCard__itemsName}>
                              {item.name}{item.postfix ? `, ${item.postfix}` : ''}:&nbsp;</p>
                           <Input changeValue={handlerOnChange}
                                  inputName={item.field_name}
                                  inputType={item.type}
                                  isArray={item.array}
                                  inputValue={currentCard[item.field_name]}
                           />
                        </label>
               ))}

            </div>

            <div className={styles.addCard__description}>
               <p className={styles.addCard__itemsName}>Текстовая часть</p>
               <TextArea inputValue={cardDescription.description}
                         changeValue={handlerOnChangeDescription}
                         deleteString={handlerOnDeleteStringDescription}
                         inputName={'description'}
                         inputRow={1}
                         isArray={true}
               />
            </div>

            <div className={styles.addCard__buttonArea}>
               <button type="submit"
                       aria-label="submit"
                       className={`${styles.addCard__button} ${styles.addCard__button_save}`}
                       disabled={!isButtonSubmitEnabled}
                       name="form_submit">
                  Сохранить
               </button>

               <button type="button"
                       className={`${styles.addCard__button} ${styles.addCard__button_cancel}`}
                       disabled={!isButtonCancelEnabled}
                       onClick={handlerOnCancel}
               >
                  Отменить
               </button>

            </div>

         </form>

         <Popup isOpened={opened}
                isTransparent={false}
                isClosableOverlay={false}>
            <Messages message={'Идет запись . . .'}
            />
         </Popup>

      </div>

   );
}

export default EditCardPage;
