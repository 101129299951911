import React, {useState} from "react";
import styles from "./EditCardPageAvatar.module.scss";
import DefaultAvatar from "../../../image/avatar-default.png";
import UploadFile from "../../../image/upload.svg";
import Clip from "../../../image/clip.svg";
import {getImageFileFormatBase64, getImageFileFormatBase64Size} from "../../../utils/utility";
// import {updateAvatarToFirebase, uploadFileToFirebaseStorage} from "../../../utils/firebaseUtility";

function EditCardPageAvatar({onSelectAvatar, currentAvatar}) {


   const [selectedAvatarFile, setSelectedAvatarFile] = useState({avatarImageFile: currentAvatar} || null)
   const [isSelectAvatarFile, setIsSelectAvatarFile] = useState(false)
   const [isChangeBlockVisible, setIsChangeBlockVisible] = useState(false)
   const [errorMessages, setErrorMessages] = useState([]);


   // console.log(selectedAvatarFile)
   // Выбор файла аватара для загрузки на сервер и проверка на соответствие условиям
   const selectAvatarFile = (event) => {
      setErrorMessages([])
      if (event.target.files[0] && event.target.files.length > 0) {
         const avatarFileExtension = event.target.files[0].name.split(".")[1].toLowerCase()
         setSelectedAvatarFile({
            avatarImageFile: event.target.files[0],
            avatarImageFileName: event.target.files[0].name,
         })

         let selectedAvatar = {
            avatarImageFile: event.target.files[0],
            avatarImageFileName: event.target.files[0].name,
         }
         getImageFileFormatBase64(event.target.files[0])
            .then((res) => {
               document.getElementById('image-avatar').src = res
               getImageFileFormatBase64Size(res)
                  .then((res) => {
                     if (event.target.files[0].size > 100000) {
                        setErrorMessages(current => [...current, `- размер ${event.target.files[0].size / 1000} Кб`]);
                        selectedAvatar = null
                     }
                     if (avatarFileExtension !== 'png' && avatarFileExtension !== 'jpg' && avatarFileExtension !== 'jpeg') {
                        setErrorMessages(current => [...current, `- тип файла .${avatarFileExtension}`]);
                        setSelectedAvatarFile(null)
                        selectedAvatar = null
                     }
                     if (res.heightUploadImage * res.widthUploadImage > 160000) {
                        setErrorMessages(current => [...current, `- размерность ${res.widthUploadImage}x${res.heightUploadImage}px`]);
                        setSelectedAvatarFile(null)
                        selectedAvatar = null
                     }

                     // Если нет ошибок и выбран файл
                     if (selectedAvatar) {
                        setIsSelectAvatarFile(true)
                        onSelectAvatar(selectedAvatar)
                     }

                  })
            })
      }
   }

   // Отображение блока для изменений в аватар
   const handlerVisibleChangeAvatarBlock = () => {
      setIsChangeBlockVisible(!isChangeBlockVisible)
      setIsSelectAvatarFile(null)
      setErrorMessages([])
      document.getElementById("photo_input").value = ''
   }

   return (
      <>
         {/*// TODO Сделать проверку если битая ссылка на изображение*/}
         <div className={styles.personInfo__blockAvatar}>

            <img src={selectedAvatarFile ? selectedAvatarFile.avatarImageFile : DefaultAvatar} alt=""
                 className={styles.personInfo__imageAvatar}
                 id='image-avatar'
            />

            <p className={styles.personInfo__linkChangeAvatar}
               onClick={handlerVisibleChangeAvatarBlock}>Выбрать файл аватара
               <span
                  className={!isChangeBlockVisible ? `${styles.personInfo__triangleRight}` : `${styles.personInfo__triangleNone}`}>
                     &nbsp;&#9658;
                  </span>
               <span
                  className={isChangeBlockVisible ? `${styles.personInfo__triangleDown}` : `${styles.personInfo__triangleNone}`}>
                     &nbsp;&#9660;
                  </span>
            </p>

            <ul className={errorMessages.length > 0 ? `${styles.errorAvatar}` : `${styles.errorAvatar__hide}`}>
               <p className={styles.errorAvatar__errorText}>Несоответствие требованиям: </p>

               {errorMessages.map((item, index) => (
                  <p key={index} className={styles.errorAvatar__errorText}>{item} </p>
               ))}
            </ul>

            <div className={isChangeBlockVisible ? `${styles.change}` : `${styles.change} ${styles.change_hide}`}>
               <label className={styles.inputBlock}>
                  <img
                     className={styles.inputBlock__img}
                     src={Clip}
                     alt=""/>
                  <p className={styles.inputBlock__text}>{!isSelectAvatarFile ? 'Выберите файл' : 'Выбран 1 файл'}</p>
                  <input name='file' type="file" id="photo_input" onChange={selectAvatarFile}
                         className={styles.inputBlock__input}/>
               </label>

               <p className={styles.change__uploadText}>Выберите файл .png или .jpg, не более 100 Кб и
                  400х400px.</p>
            </div>

         </div>
      </>
   );
}

export default EditCardPageAvatar;
